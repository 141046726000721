import logoImage from "../../resources/img/logo-small-gray.svg";
import closeImage from "../../resources/img/close.svg";
import { navigateToMainPage } from "../../helpers/NavigationHelper";

export const ContainerWithLogo = ({
  components,
  addClose = false,
  closeAction = null,
}) => {
  var closeIcon;
  if (addClose) {
    closeIcon = (
      <div
        className="close-item close-item-add-project"
        onClick={() => closeAction()}
      >
        <img src={closeImage} className="popup-close-icon" alt="close" />
      </div>
    );
  }

  const items = (
    <div className="container h-100">
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="d-flex flex-column authentication-container">
          <div
            className="container-with-logo-header d-flex justify-content-between"
          // onClick={() => {
          //   navigateToMainPage();
          // }}
          >
            <img
              src={logoImage}
              className="authentication-container-logo"
              alt="logo-image"
            />
            {closeIcon}
          </div>

          {components}
        </div>
      </div>
    </div>
  );

  return items;
};
