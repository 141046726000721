export const BLOCK = "block";
export const UPDATE = "update";
export const NOTIFY = "notify";

const TypeComponent = ({ type }) => {
  var className = "type-component";
  var title = "";

  if (type === BLOCK) {
    className += " block-component";
    title = "Block";
  }

  if (type === UPDATE) {
    className += " update-component";
    title = "Update";
  }

  if (type === NOTIFY) {
    className += " notify-component";
    title = "Notify";
  }

  return <div className={className}>{title}</div>;
};

export default TypeComponent;
