export const RANGE_30_DAYS = "30D";
export const RANGE_7_DAYS = "7D";
export const RANGE_1_DAY = "1D";
export const RANGE_1_HOUR = "1h";

const DateRangePickerOption = ({
  title,
  selectKey,
  setSelected,
  isSelected,
}) => {
  var className = "date-range-option align-middle";
  if (isSelected) {
    className += " date-range-selected";
  }

  return (
    <div
      className={className}
      onClick={() => {
        setSelected(selectKey);
      }}
    >
      {title}
    </div>
  );
};

const DateRangePicker = ({ selectedRange, setSelectedRange }) => {
  return (
    <div className="date-range-picker constainer">
      <div className="row date-range-option-row">
        <div className="col date-range-option-col">
          <DateRangePickerOption
            title={"30 days"}
            selectKey={RANGE_30_DAYS}
            setSelected={setSelectedRange}
            isSelected={selectedRange === RANGE_30_DAYS}
          />
        </div>
        <div className="col date-range-option-col">
          <DateRangePickerOption
            title={"7 days"}
            selectKey={RANGE_7_DAYS}
            setSelected={setSelectedRange}
            isSelected={selectedRange === RANGE_7_DAYS}
          />
        </div>
        <div className="col date-range-option-col">
          <DateRangePickerOption
            title={"today"}
            selectKey={RANGE_1_DAY}
            setSelected={setSelectedRange}
            isSelected={selectedRange === RANGE_1_DAY}
          />
        </div>
        <div className="col date-range-option-col">
          <DateRangePickerOption
            title={"last hour"}
            selectKey={RANGE_1_HOUR}
            setSelected={setSelectedRange}
            isSelected={selectedRange === RANGE_1_HOUR}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
