import Chart from "react-apexcharts";
import Gradient from "javascript-color-gradient";

const percetageOf = (items, total) => {
  if (total === 0) {
    return 0;
  }

  return ((items * 100) / total).toFixed(0);
};

const UsersOnAppVersionsGraph = ({ appVersionsUsers }) => {
  const data = {
    values: [],
    versions: [],
  };

  if (appVersionsUsers != null) {
    console.log(appVersionsUsers, "appVersionsUsers");

    var keys = Object.entries(appVersionsUsers)
      .map(([key, value]) => key)
      .sort((a, b) => (a > b ? -1 : 1));

    var valuesSum = Object.entries(appVersionsUsers)
      .map(([key, value]) => value)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    keys.forEach((key) => {
      const value = appVersionsUsers[key];

      data.values.push(percetageOf(value, valuesSum));
      data.versions.push(key);
    });
  }

  const numberOfValues = data.values.length;

  const colorGradient = new Gradient();
  colorGradient.setColorGradient("#5064FF", "#7E95FF");
  colorGradient.setMidpoint(numberOfValues);

  var colors = colorGradient.getColors();

  var state = {
    series: [
      {
        name: "",
        data: data.values,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          rangeBarOverlap: false,
          borderRadius: 14,
          borderRadiusApplication: "end",
          distributed: true, // this line is mandatory
          dataLabels: {
            position: "center",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      states: {
        active: {
          filter: {
            type: "none" /* none, lighten, darken */,
          },
        },
        hover: {
          filter: {
            type: "none" /* none, lighten, darken */,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: 0,
        style: {
          fontFamily: "Inter-SemiBold",
          fontSize: "14px",
        },
      },
      xaxis: {
        categories: data.versions,
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          trim: true,
          style: {
            colors: "#707A88",
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          trim: true,
          show: true,
          offsetX: -5,
          style: {
            colors: "#707A88",
          },
        },
      },

      title: {
        show: false,
        text: "",
      },
      legend: {
        show: false,
      },
      labels: {
        trim: true,
      },
      colors: colors,
    },
  };

  return (
    <div className="users-on-app-versions-graph">
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        height="350"
      />
    </div>
  );
};

export default UsersOnAppVersionsGraph;
