import React from 'react'



const HowWorkRow = ({ asset, assetSmall, title, description, icon, reverse }) => {
    var wrapperClassName = "row how-work-page-row"

    if (reverse) {
        wrapperClassName += " how-work-row-reverse"
    }

    return (
        <div className={wrapperClassName} >
            <div className='col how-work-row-img-wrapper'>
                <div className='display-on-big'>
                    <img src={asset} className='how-work-row-asset' ></img>
                </div>
                <div className='display-on-small'>
                    <img src={assetSmall} className='how-work-row-asset img-fluid' ></img>
                </div>
            </div>

            <div className='col how-work-row-text-wrapper'>
                <div className='display-on-big'>
                    <img src={icon}></img>
                </div>
                <div className='d-flex flex-column'>
                    <h1 className='how-work-row-title'>
                        {title}
                    </h1>
                    <p className='how-work-row-subtitle'>
                        {description}
                    </p>
                </div>
            </div>

        </div>
    )
}

export default HowWorkRow