const EditButton = ({ action }) => {
  return (
    <div className="edit-button-container">
      <button className="edit-button" onClick={action}>
        Edit
      </button>
    </div>
  );
};

export default EditButton;
