import { useState } from "react";

import downArrowImage from "../../../resources/img/down-arrow.svg";
import upArrowImage from "../../../resources/img/up-arrow.svg";
import plusImage from "../../../resources/img/purple-plus.svg";
import useComponentVisible from "../../../helpers/ComponentVisibility";

const DropDown = ({
  selectedValueID,
  setSelectedValueID,
  values,
  addNewProject,
}) => {
  var valuesItems = [];
  var image = downArrowImage;
  var project = "";

  if (selectedValueID) {
    const foundProj = values.find((e) => e.id === selectedValueID);
    if (foundProj) {
      project = foundProj.name;
    }
  }
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const selectValue = (value) => {
    toggleMenu();
    setSelectedValueID(value.id);
  };

  const addNewItemButton = (
    <div className="add-new-project-container">
      <button
        className="transparent-button add-new-project-button"
        onClick={addNewProject}
      >
        <div className="image">
          <img src={plusImage} alt="add" />
        </div>
        <div className="text">Add new project</div>
      </button>
    </div>
  );

  if (isComponentVisible) {
    // Add Values
    values.forEach((element) => {
      var className = "value-item";
      if (element.id === selectedValueID) {
        className += " drop-down-selected-option";
      }
      const valueItem = (
        <div className={className} onClick={() => selectValue(element)}>
          <div className="text">{element.name}</div>
        </div>
      );
      valuesItems.push(valueItem);
    });

    valuesItems.push(addNewItemButton);

    image = upArrowImage;
    project = "Project";
  }

  const dropDownSelector = (
    <div ref={ref} className="drop-down-selector">
      <div className="selected-value" onClick={toggleMenu}>
        <div className="text">{project}</div>
        <div className="image">
          <img src={image} className="disclosure-icon" alt="arrow" />
        </div>
      </div>
      <div className="values-container">{valuesItems}</div>
    </div>
  );

  return dropDownSelector;
};

export default DropDown;
