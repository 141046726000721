import React from 'react'
import { PricingPlanCard } from './PricingPlanCard'
import { Carousel } from 'react-responsive-carousel'

const PricingCarousel = ({ plan, pricingCards }) => {
    return (

        <Carousel showStatus={false} showArrows={false} showThumbs={false} swipeable={true} emulateTouch={true} className='pricing-carousel'>
            {pricingCards.map((item, index) => (
                <div className='d-flex justify-content-center align-items-center   '>
                    <PricingPlanCard
                        planType={item.planType}
                        planPrice={item.planPrice}
                        planPeriod={plan}
                        checkList={item.checkList}
                        secondaryCard={item.secondaryCard}
                        buttonTitle={item.buttonTitle}
                        action={item.action}
                    />
                </div>
            ))}


        </Carousel>



    )
}

export default PricingCarousel