export const SimpleButton = ({ text, action, extraClass = null }) => {
  var className = "simple-button";
  if (extraClass) {
    className += " " + extraClass;
  }

  return (
    <button className={className} onClick={action}>
      {text}
    </button>
  );
};
