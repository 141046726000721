import { IOS, WEB, ANDROID } from "../../../../constants/PlatformConstants";

export const appForType = (type, project) => {
  if (!project || !type) {
    return null;
  }

  if (type === WEB) {
    return project.web_application;
  }

  if (type === IOS) {
    return project.ios_application;
  }

  if (type === ANDROID) {
    return project.android_application;
  }

  return null;
};
