import TypeComponent, { BLOCK, NOTIFY, UPDATE } from "../../../../generic/Type";

import blockIcon from "../../../../../../resources/img/block-icon.svg";
import notifyIcon from "../../../../../../resources/img/notify-icon.svg";
import updateIcon from "../../../../../../resources/img/update-icon.svg";

export const RANGES = ["Exact (=)", "Lower then (<)"];

export const iconForType = (type) => {
  if (type === BLOCK) {
    return blockIcon;
  }

  if (type === NOTIFY) {
    return notifyIcon;
  }

  if (type === UPDATE) {
    return updateIcon;
  }

  return null;
};

export const actionTitle = (type, dismissable) => {
  if (type === BLOCK) {
    return "Update";
  }

  if (type === NOTIFY) {
    if (!dismissable) {
      return null;
    }

    return "Continue";
  }

  if (type === UPDATE) {
    return "Update";
  }

  return null;
};
