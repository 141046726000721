import { useState } from "react";

import checkmarkIcon from "../../../../../resources/img/checkmark.svg";
import SelectorButton from "../../../generic/SelectorButton";

const MONTHLY = "monthly";
const ANUALLY = "annually";

const SubscriptionTypeSelctor = ({ selectedValue, setSelectedValue }) => {
  const monthly = (
    <SelectorButton
      title={"Monthly"}
      selectionKey={MONTHLY}
      isSelected={selectedValue === MONTHLY}
      selectValue={setSelectedValue}
      extraClassName={"subscription-type-selector-button"}
    />
  );

  const anually = (
    <SelectorButton
      title={"Annually"}
      selectionKey={ANUALLY}
      isSelected={selectedValue === ANUALLY}
      selectValue={setSelectedValue}
      extraClassName={"subscription-type-selector-button"}
    />
  );

  return (
    <div className="subscription-type-selector">
      <div className="container">
        <div className="row">
          <div className="col-6 no-padding">{anually}</div>
          <div className="col-6 no-padding">{monthly}</div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionButton = ({
  title,
  highlighted,
  extraClassName = null,
  onClickAction,
}) => {
  var className = "subscription-button-container";
  if (highlighted) {
    className += " subscribe-button-container-highlighted";
  }

  if (extraClassName) {
    className += " " + extraClassName;
  }

  return (
    <div className={className}>
      <button
        className="transparent-button subscribe-button"
        onClick={() => {
          onClickAction();
        }}
      >
        {title}
      </button>
    </div>
  );
};

const SubscriptionMembershipPerk = ({ title, highlighted }) => {
  var className = "subscription-membership-perk";
  if (highlighted) {
    className = "subscription-membership-perk-highlighted";
  }

  return (
    <div className={className}>
      <div className="image-container">
        <img className="image" src={checkmarkIcon} alt="checkmark" />
      </div>
      <div className="text-container">{title}</div>
    </div>
  );
};

const SubscriptionMembership = ({
  actionTitle,
  action,
  highlighted,
  type,
  price,
  perks,
  contactSales = false,
}) => {
  var className = "subscription-membership-container";
  if (highlighted) {
    className += " subscription-membership-container-highlighted";
  }

  var perksToDisplay = [];
  perks.forEach((element) => {
    perksToDisplay.push(
      <SubscriptionMembershipPerk
        title={element.title}
        highlighted={element.highlighted}
      />
    );
  });

  var priceContainer = (
    <div className="price-container">
      <span className="value">{price}</span>/
      <span className="month">month</span>
    </div>
  );

  var subscriptionContainer = (
    <div className="start-button-container">
      <SubscriptionButton
        title={actionTitle}
        highlighted={highlighted}
        extraClassName={"mt-42"}
        onClickAction={action}
      />
    </div>
  );

  if (contactSales) {
    priceContainer = (
      <div className="price-container">
        <span className="value">{price}</span>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="type">{type}</div>
      {priceContainer}
      {subscriptionContainer}
      <div className="perks-container">{perksToDisplay}</div>
    </div>
  );
};

const NotSubscribedContainer = ({ setLoading }) => {
  const [subscriptionsType, setSubscriptionsType] = useState(ANUALLY);

  const sendEmailHandler = () => {
    window.location.href = "mailto:info@semaphr.com";
  };

  const freePerks = [
    { title: "100% free, forever", highlighted: true },
    { title: "Unlimited logs", highlighted: true },
    { title: "Native and cross platform SDKs", highlighted: true },
    { title: "Block, notify and update messages", highlighted: false },
    { title: "Usage status", highlighted: false },
    { title: "Unlimited team members", highlighted: false },
  ];

  const businessPerks = [
    { title: "Everything in startup", highlighted: true },
    { title: "Messages customization (branding)", highlighted: true },
    { title: "Instant messages display", highlighted: true },
    { title: "Analytics export", highlighted: false },
    { title: "Integration support", highlighted: false },
    { title: "Enhanced encryption and security", highlighted: false },
  ];

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <div className="row">
        <div className="col-8 two-columns-fields-size">
          <div className="integration-description">
            Semaphr offers flexible subscription plans that fit your needs and
            budget, with <br></br> options for startups and enterprises and
            different levels of access and support.
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center mb-24">
          {/* <SubscriptionTypeSelctor
            selectedValue={subscriptionsType}
            setSelectedValue={setSubscriptionsType}
          /> */}
        </div>

        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <SubscriptionMembership
                  type={"Startup"}
                  price={"$0"}
                  highlighted={false}
                  perks={freePerks}
                  actionTitle={"Current plan"}
                  action={() => {}}
                />
              </div>
              {/* <div className="col-4">
                <SubscriptionMembership
                  type={"Team"}
                  price={"$123"}
                  highlighted={true}
                />
              </div> */}
              <div className="col-4">
                <SubscriptionMembership
                  type={"Business"}
                  price={"Custom"}
                  perks={businessPerks}
                  highlighted={true}
                  contactSales={true}
                  actionTitle={"Contact sales"}
                  action={() => {
                    sendEmailHandler();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default NotSubscribedContainer;
