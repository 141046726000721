import messageUnselectedIcon from "../../../../../../resources/img/unselected-round.svg";
import messageSelectedBlockIcon from "../../../../../../resources/img/selected-block-round.svg";
import messageSelectedUpdateIcon from "../../../../../../resources/img/selected-update-round.svg";
import messageSelectedNotifyIcon from "../../../../../../resources/img/selected-notify-round.svg";

import enabledButton from "../../../../../../resources/img/enabled-button.svg";
import disabledButton from "../../../../../../resources/img/disabled-button.svg";

import TypeComponent, { BLOCK, NOTIFY, UPDATE } from "../../../../generic/Type";

const imageForMessageType = (selected, type) => {
  if (!selected) {
    return messageUnselectedIcon;
  }

  if (type === BLOCK) {
    return messageSelectedBlockIcon;
  }

  if (type === UPDATE) {
    return messageSelectedUpdateIcon;
  }

  if (type === NOTIFY) {
    return messageSelectedNotifyIcon;
  }
};

const MESSAGE_TYPE_DESCRIPTION = {
  app: {
    block:
      "Users need to update the app to the latest version in order to continue using it.",
    update:
      "Users need to update the app to access new features or improvements.",
    notify:
      "Users are notified if there is an issue with the app. They do NOT need to update the app.",
  },
  web: {
    block:
      "The website is inaccessible or not functioning properly. Users need to wait until the website is up again. They can not skip the message.",
    notify:
      "The website is up and functioning, it means that the site is accessible and performing. Users can access the site's pages after reading the message.",
  },
};

const MessageType = ({
  type,
  selected,
  setSelected,
  app = true,
  skipAllowed,
  setSkipAllowed,
}) => {
  var containerClassName = "message-type";
  var skipAllowedText = <div className="text">Allow skipping</div>;

  var buttonImage = disabledButton;
  if (skipAllowed) {
    buttonImage = enabledButton;
  }

  var allowSkipingButton = (
    <div className="button">
      <button
        className="transparent-button"
        onClick={() => {
          setSkipAllowed(!skipAllowed);
        }}
      >
        <img src={buttonImage} alt="disable-button" />
      </button>
    </div>
  );

  const title = type.toUpperCase();
  var description = "";

  console.log("type: ", type, selected);

  const image = imageForMessageType(selected, type);

  if (app) {
    description = MESSAGE_TYPE_DESCRIPTION["app"][type];
  } else {
    description = MESSAGE_TYPE_DESCRIPTION["web"][type];
  }

  if (type === BLOCK) {
    skipAllowedText = <div>(!) Skip NOT allowed</div>;
    allowSkipingButton = null;
  }

  if (selected) {
    containerClassName += " message-type-" + type;
  }

  return (
    <div
      className={containerClassName}
      onClick={() => {
        setSelected();
      }}
    >
      <div className="header">
        <div className="title">{title}</div>
        <div className="image">
          <img src={image} className="close-image" alt="close" />
        </div>
      </div>
      <div className="description">{description}</div>
      <div className="mention">
        {skipAllowedText}
        {allowSkipingButton}
      </div>
    </div>
  );
};

export default MessageType;
