export const SiteHeaderActionButton = ({
    title,
    action,
    className = null,
}) => {
    var containerClass = ` `;
    var actionButtonHeaderClassName = "site-header-action-button";
    return (
        <div className={containerClass}>
            <button onClick={action} className={actionButtonHeaderClassName}>
                {title}
            </button>
        </div>

    )
}