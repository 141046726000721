import $ from "jquery";

import { useEffect, useState } from "react";
import SideBar from "../../components/platform/sidebar/SideBar";
import { MENU_DASHBOARD } from "../../constants/SideBarConstants";
import PlatformDetails from "../../components/platform/details/PlatformDetails";
import { useUserContext } from "../../components/model/context/UserContext";
import { navigateToLogin } from "../../helpers/NavigationHelper";
import CreateNewProjectPage from "../authentication/CreateNewProjectPage";
import { getAllProjectsAPICall } from "../../components/model/api/ProjectsService";
import Notifications from "../../helpers/Notifications";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const PlatformPage = ({ setLoading }) => {
  const [projects, setProjects] = useState([]);

  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(MENU_DASHBOARD);
  const { currentUser, fetchCurrentUser } = useUserContext();
  const [addProject, setAddProject] = useState(false);

  const selectedProject = projects.find((p) => p.id === selectedProjectID);

  const fetchProjects = () => {
    setLoading(true);
    getAllProjectsAPICall(
      (response) => {
        setLoading(false);
        setProjects(response.data.projects);
      },
      (error) => {
        Notifications.showGenericError();
        setLoading(false);
      }
    );
  };

  const setInitialState = () => {
    const user = currentUser();

    if (!user) {
      // Redirect to login
      navigateToLogin();

      return;
    }

    fetchProjects();
  };

  const addNewProject = () => {
    setAddProject(true);
  };

  const projectsSetHandler = () => {
    if (projects.length > 0 && !selectedProjectID) {
      setSelectedProjectID(projects[0].id);
    }
  };

  const refetchCurrentUser = () => {
    setLoading(true);
    fetchCurrentUser(
      (response) => {
        setLoading(false);
        // User is authenticated go to dashboard
        setInitialState();
        setAddProject(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    projectsSetHandler();
  }, [projects]);

  var content = (
    <div className="no-margins-div">
      <SideBar
        setLoading={setLoading}
        projects={projects}
        selectedProjectID={selectedProjectID}
        setSelectedProjectID={setSelectedProjectID}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        addNewProject={addNewProject}
        currentUser={currentUser}
      />
      <PlatformDetails
        setLoading={setLoading}
        selectedMenu={selectedMenu}
        selectedProject={selectedProject}
        refreshProjects={fetchProjects}
        setSelectedMenu={setSelectedMenu}
      />
    </div>
  );

  if (projects.length === 0 || addProject) {
    content = (
      <CreateNewProjectPage
        setLoading={setLoading}
        refreshCurrentUser={refetchCurrentUser}
        addProject={addProject}
        setAddProject={setAddProject}
      />
    );
  }

  return (
    <div id="platform" className="full-screen-item">
      {content}
      <TrackingComponent />
    </div>
  );
};

export default PlatformPage;
