import { useState } from "react";
import PlatformHeader from "../../header/PlatformHeader";
import SectionTitle from "../../generic/DashboardSectionTitle";
import WhiteInputField from "../../generic/WhiteInputField";
import { SimpleButton } from "../../../generic/SimpleButton";

import checkmarkIcon from "../../../../resources/img/checkmark.svg";
import SelectorButton from "../../generic/SelectorButton";
import NotSubscribedContainer from "./components/NotSubscribedContainer";
import SubscribedContainer from "./components/SubscribedContainer";

const Subscriptions = ({ setLoading }) => {
  const title = "Subscriptions";
  const showFilters = false;

  const header = <PlatformHeader title={title} showFilters={showFilters} />;

  var content = <NotSubscribedContainer setLoading={setLoading} />;
  // content = <SubscribedContainer setLoading={setLoading} />;

  return (
    <div id="subscriptions">
      {header}
      {content}
    </div>
  );
};

export default Subscriptions;
