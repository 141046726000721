import dashboardIcon from "../../../resources/img/dashboard.svg";
import dashboardIconSelected from "../../../resources/img/dashboard-selected.svg";

import messagesIcon from "../../../resources/img/messages.svg";
import messagesIconSelected from "../../../resources/img/messages-selected.svg";

import subscriptionsIcon from "../../../resources/img/subscriptions.svg";
import subscriptionsIconSelected from "../../../resources/img/subscriptions-selected.svg";

import settingsIcon from "../../../resources/img/settings.svg";
import settingsIconSelected from "../../../resources/img/settings-selected.svg";

import settingsLine from "../../../resources/img/settings-lines.svg";

import {
  MENU_MESSAGES,
  MENU_SETTINGS,
  MENU_SUBSCRIPTIONS,
  MENU_DASHBOARD,
  MENU_MEMBERS,
  MENU_INTEGRATIONS,
} from "../../../constants/SideBarConstants";

export const MenuItem = ({
  title,
  image,
  selectedImage,
  selected,
  setSelected,
  selectionKey,
}) => {
  var imageToShow = image;
  var className = "menu-item";

  if (selected) {
    imageToShow = selectedImage;
    className += " menu-item-selected";
  }

  return (
    <div className={className} onClick={() => setSelected(selectionKey)}>
      <div className="image">
        <img src={imageToShow} className="menu-item-icon" alt="icon" />
      </div>
      <div className="text">{title}</div>
    </div>
  );
};

export const SubMenuItem = ({ title, selected, setSelected, selectionKey }) => {
  var className = "sub-menu-item";

  if (selected) {
    className += " sub-menu-item-selected";
  }

  return (
    <div className={className} onClick={() => setSelected()}>
      {title}
    </div>
  );
};

export const SettingsMenuItem = ({
  title,
  image,
  selectedImage,
  selected,
  setSelected,
  selectedValue,
  selectionKey,
}) => {
  var imageToShow = image;
  var className = "menu-item";
  var options;

  if (selected) {
    imageToShow = selectedImage;
    className += " menu-item-selected-no-background";

    options = (
      <div className="settings-line-container">
        <div className="lines">
          <img src={settingsLine} className="settings-line" alt="icon" />
        </div>
        <div className="options">
          <SubMenuItem
            title={"Members"}
            image={dashboardIcon}
            selectedImage={dashboardIconSelected}
            selected={selectedValue === MENU_MEMBERS}
            setSelected={() => {
              setSelected(MENU_MEMBERS);
            }}
            selectionKey={MENU_MEMBERS}
          />
          <SubMenuItem
            title={"Integrations"}
            image={dashboardIcon}
            selectedImage={dashboardIconSelected}
            selected={selectedValue === MENU_INTEGRATIONS}
            setSelected={() => {
              setSelected(MENU_INTEGRATIONS);
            }}
            selectionKey={MENU_INTEGRATIONS}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="menu-item-settings">
      <div className={className} onClick={() => setSelected(MENU_MEMBERS)}>
        <div className="image">
          <img src={imageToShow} className="menu-item-icon" alt="icon" />
        </div>
        <div className="text">{title}</div>
      </div>
      {options}
    </div>
  );
};

export const MenuItems = ({ selectedValue, setSelectedValue }) => {
  const hasSelectedValue = (key) => {
    setSelectedValue(key);
  };

  return (
    <div className="menu-items">
      <MenuItem
        title={"Dashboard"}
        image={dashboardIcon}
        selectedImage={dashboardIconSelected}
        selected={selectedValue === MENU_DASHBOARD}
        setSelected={hasSelectedValue}
        selectionKey={MENU_DASHBOARD}
      />
      <MenuItem
        title={"Messages"}
        image={messagesIcon}
        selectedImage={messagesIconSelected}
        selected={selectedValue === MENU_MESSAGES}
        setSelected={hasSelectedValue}
        selectionKey={MENU_MESSAGES}
      />
      <MenuItem
        title={"Subscriptions"}
        image={subscriptionsIcon}
        selectedImage={subscriptionsIconSelected}
        selected={selectedValue === MENU_SUBSCRIPTIONS}
        setSelected={hasSelectedValue}
        selectionKey={MENU_SUBSCRIPTIONS}
      />
      <SettingsMenuItem
        title={"Settings"}
        image={settingsIcon}
        selectedImage={settingsIconSelected}
        selectedValue={selectedValue}
        selected={
          selectedValue === MENU_MEMBERS || selectedValue === MENU_INTEGRATIONS
        }
        setSelected={hasSelectedValue}
        selectionKey={MENU_SETTINGS}
      />
    </div>
  );
};

export default MenuItems;
