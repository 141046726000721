const SelectorButton = ({
  title,
  selectionKey,
  isSelected,
  selectValue,
  extraClassName = null,
}) => {
  var className = "platform-selector-button";
  if (isSelected) {
    className += " platform-selector-button-selected";
  }

  if (extraClassName) {
    className += " " + extraClassName;
  }

  const buttonPressed = () => {
    selectValue(selectionKey);
  };

  return (
    <div className={className} onClick={buttonPressed}>
      {title}
    </div>
  );
};

export default SelectorButton;
