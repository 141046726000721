const MessageItemTitle = ({ title, className = null, optional = false }) => {
  var currentClassName = "message-item-title";
  if (className) {
    currentClassName += " " + className;
  }

  var optionalValue;
  if (optional) {
    optionalValue = (
      <span className="message-item-title-optional"> (optional)</span>
    );
  }

  return (
    <div className={currentClassName}>
      {title}
      {optionalValue}
    </div>
  );
};

export default MessageItemTitle;
