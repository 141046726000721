class LocalStorage {
  static setAuthenthicationToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }

  static getAuthenticationToken() {
    return localStorage.getItem("access_token");
  }

  static setRefreshToken(refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }

  static getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  static setCurrentUser(user) {
    if (user) {
      let strValue = JSON.stringify(user);
      localStorage.setItem("current_user", strValue);
    }
  }

  static getCurrentUser() {
    let strValue = localStorage.getItem("current_user");
    const user = JSON.parse(strValue);

    return user;
  }

  static logoutUser() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("current_user");
  }
}

export default LocalStorage;
