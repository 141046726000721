import React from 'react'
import { ReactComponent as LogoWhite } from '../../../resources/img/logo-white.svg'
import SocialMediaWrapper from './SocialMediaWrapper'
const LeftSide = () => {
    const year = new Date();
    return (
        <div className='site-footer-left-side-container '>
            <LogoWhite />
            <div className='display-on-big'>
                <p className='footer-copyright'>
                    Copyright © {year.getFullYear()} <span>semaphr</span>.
                    <br /> All rights reserved.
                </p>
            </div>

            <SocialMediaWrapper />
        </div>
    )
}

export default LeftSide