import { POST, GET, DELETE, APIVersion, PUT } from "./APIHelper";

export const getRulesAPICall = (
  projectID,
  term,
  archived,
  platform,
  onSuccess,
  onError
) => {
  const data = {
    term: term,
    archived: archived,
    platform: platform,
  };

  const path = APIVersion + "/projects/" + projectID + "/rules";
  POST(path, data, onSuccess, onError);
};

export const addMessageAPICall = (
  projectID,
  title,
  message,
  dismissable,
  platform,
  version,
  applyTo,
  ruleType,
  startD,
  end,
  onSuccess,
  onError
) => {
  var data = {
    title: title,
    message: message,
    dismissible: dismissable,
    platform: platform,
    version: version,
    apply_to: applyTo,
    rule_type: ruleType,
    start: startD.toISOString(),
  };

  if (end) {
    data["end"] = end.toISOString();
  }

  const path = APIVersion + "/projects/" + projectID + "/rules/create";
  POST(path, data, onSuccess, onError);
};

export const editMessageAPICall = (
  projectID,
  messageID,
  title,
  message,
  dismissable,
  platform,
  version,
  applyTo,
  ruleType,
  startD,
  end,
  onSuccess,
  onError
) => {
  var data = {
    title: title,
    message: message,
    dismissible: dismissable,
    platform: platform,
    version: version,
    apply_to: applyTo,
    rule_type: ruleType,
    start: startD.toISOString(),
  };

  if (end) {
    data["end"] = end.toISOString();
  }

  const path = APIVersion + "/projects/" + projectID + "/rules/" + messageID;
  PUT(path, data, onSuccess, onError);
};

export const archiveMessageAPICall = (
  projectID,
  messageID,
  onSuccess,
  onError
) => {
  var data = {};

  const path =
    APIVersion + "/projects/" + projectID + "/rules/" + messageID + "/archive";
  PUT(path, data, onSuccess, onError);
};
