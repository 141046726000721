import Chart from "react-apexcharts";

const AffectedUsersLegend = ({ main, label, value, percentage }) => {
  var colorClass = "accent";
  if (!main) {
    colorClass = "secondary-accent";
  }

  return (
    <div className="affected_legend container">
      <div className="row">
        <div className={"col small-rect " + colorClass}></div>
        <div className="col label">{label}</div>
      </div>
      <div className="row">
        <div className="value-percentage-container">
          <div className="value">{value}</div>
          <div className="percentage">{percentage}</div>
        </div>
      </div>
    </div>
  );
};

const AffectedUsersGraph = ({ affectedUsers }) => {
  var totalUsers = 0;
  var affectedUsersPercentage = 0;
  var affectedUsersValue = 0;
  var safeUsers = 0;
  var affectedUsersPercentage = 0;

  if (affectedUsers) {
    affectedUsersValue = affectedUsers.affected_users;
    totalUsers = affectedUsers.unaffected_users + affectedUsers.affected_users;

    if (totalUsers !== 0) {
      affectedUsersPercentage = (
        (affectedUsersValue * 100) /
        totalUsers
      ).toFixed(0);
    }

    safeUsers = totalUsers - affectedUsersValue;
  }

  var safeUsersPercentage = 100 - affectedUsersPercentage;

  const state = {
    options: {
      plotOptions: {
        radialBar: {
          hollow: {
            size: "68%",
          },
          dataLabels: {
            show: false,
          },
          track: {
            background: "#C5CFFF",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
          },
          fill: {
            type: "solid",
            colors: ["#5064FF"],
          },
        },
      },
      fill: {
        type: "solid",
        colors: ["#5064FF"],
      },
      color: "#5064FF",
      stroke: {
        lineCap: "round",
      },
    },
    series: [affectedUsersPercentage],
  };

  return (
    <div className="affected_users_chart container">
      <div className="row">
        <div className="col">
          <div className="legend">
            <div className="number">{totalUsers}</div>
            <div className="label">Total users</div>
          </div>
          <Chart
            options={state.options}
            series={state.series}
            type="radialBar"
            height="300"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <AffectedUsersLegend
            main={true}
            label={"Affected users"}
            value={affectedUsersValue}
            percentage={affectedUsersPercentage + "%"}
          />
        </div>
        <div className="col">
          <AffectedUsersLegend
            main={false}
            label={"Safe users"}
            value={safeUsers}
            percentage={safeUsersPercentage + "%"}
          />
        </div>
      </div>
    </div>
  );
};

export default AffectedUsersGraph;
