import React from "react";
import {
  navigateToAnotherSite,
  navigateToHomeWithPath,
  navigateToPrivacy,
  navigateToTerms,
} from "../../../helpers/NavigationHelper";

const RightSide = () => {
  const sendEmailHandler = (e) => {
    e.preventDefault();
    window.location.href = "mailto:info@semaphr.com";
  };

  return (
    <div className="footer-utils">
      <div className="row">
        <div className="col footer-utils-wrapper">
          <p>Support</p>
          <a onClick={() => navigateToHomeWithPath("#pricing")}>Pricing</a>
          <a onClick={() => navigateToAnotherSite("https://github.com/semaphr")}>
            Documentation
          </a>
          <a onClick={() => navigateToHomeWithPath("#faq")}>FAQ</a>
          <a onClick={(e) => sendEmailHandler(e)}>
            Contact
          </a>
        </div>
        <div className="col footer-utils-wrapper">
          <p>Legal</p>
          <a
            onClick={() => {
              navigateToPrivacy();
            }}
          >
            Privacy Policy
          </a>
          <a
            onClick={() => {
              navigateToTerms();
            }}
          >
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default RightSide;
