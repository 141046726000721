import React from "react";
import FaqItem from "./FaqItem";

const FaqPage = () => {
  const faqItems = [
    {
      title: "Are there any costs involved?",
      text: "Semaphr is free and it will remain free for startups and small companies, you can add as many apps as you want, no need to add a credit card. For enterprises, with a large amount of users, please reach out to our support for individual plans and features.",
    },
    {
      title: "How can I use this service if I have multiple environments?",
      text: "You can simply create a new app, with a new API key for each environment.",
    },
    {
      title: "When will the users see the message?",
      text: "The messages will be shown when the user brings the app to the foreground. Real-time alerts are available in our enterprise plan.",
    },
    {
      title: "Is my data secure?",
      text: "All the transmitted data is secured, we don't store any details about the users, we only care about the app version they run on their device.",
    },
    {
      title: "Can you block only certain versions of the app?",
      text: "With Semaphr, you can block certain versions of your app, so that your users have to update to the latest version, in this way, you can migrate users from older versions of your APIs and apps towards new ones.",
    },
    {
      title: "Does Semaphr work for all my older versions?",
      text: "Semaphr only works for apps that have integrated the SDK. Previous versions can not be managed.",
    },
    {
      title: "How long does it take to integrate the Semaphr in my app?",
      text: "To integrate Sempahr, you need a maximum of 10 minutes. It's a very simple process detailed in our documentation.",
    },
    {
      title: "Does it work on React Native?",
      text: "Yes, you can configure the SDK either native (iOS and Android) or in React.",
    },
  ];

  return (
    <div className="" id="faq">
      <div className="container faq-page-container">
        <div className="faq-page-title">
          <h1>FAQs</h1>
        </div>
        <div className="faq-items-list">
          {faqItems.map((item, index) => (
            <FaqItem key={index} title={item.title} text={item.text} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
