import { toast } from "react-toastify";

class Notifications {
  static showGenericError() {
    // toast.success("Wow so easy222!");
    toast.error("Something went wrong, please try again");
  }

  static showError(message) {
    toast.error(message);
  }

  static showSuccess(message) {
    toast.success(message);
  }
}

export default Notifications;
