import $ from "jquery";

// import blogPostImage from "../resources/img/blog-image.svg";
import TrackingComponent from "../../components/tracking/TrackingComponent";
import { generateTwoRandomBlogPostsFor } from "../../components/blog/BlogPostBuilder";
import BlogPostItem from "../../components/generic/BlogPostItem";
import SiteHeader from "../../components/site/header/SiteHeader";
import SiteFooter from "../../components/site/footer/SiteFooter";

const BlogPostPage = ({ setLoading, blogPost }) => {
  console.log("blogPost", blogPost);

  const otherBlogPosts = generateTwoRandomBlogPostsFor(blogPost);
  const otherBlogPost1 = otherBlogPosts[0]();
  const otherBlogPost2 = otherBlogPosts[1]();

  return (
    <div>
      <SiteHeader />
      <div className="blog-page white">
        <div className="container mb-60">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="blog-post-image-container">
                <img
                  src={blogPost.headerImage}
                  className="blog-page-post-image"
                  alt="blog-post"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="blog-page-title">{blogPost.title}</div>
              <div className="blog-page-reading-time">
                {blogPost.readTime} mins. reading time
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 blog-page-text">{blogPost.content}</div>
          </div>

          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="blog-page-read-more-articles">
                Read more articles from our blog
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <BlogPostItem
                    blogPost={otherBlogPost1}
                    noDescription={true}
                  />
                </div>
                <div className="col-lg-6">
                  <BlogPostItem
                    blogPost={otherBlogPost2}
                    noDescription={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-80"></div>
      </div>
      <TrackingComponent />
      <SiteFooter />
    </div>
  );
};

export default BlogPostPage;
