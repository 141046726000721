import error from "../../resources/img/error.svg";

export const ErrorLabel = ({ text }) => {
  return (
    <div className="error-container d-flex justify-content-left">
      <img src={error} className="error-image" alt="error-image" />
      <span className="error-text">{text}</span>
    </div>
  );
};
