import {
  INTEGRATION_PENDING,
  INTEGRATION_NOT_CONNECTED,
  INTEGRATION_CONNECTED,
} from "../../../generic/PlatformHelper";

const IntegrationStatus = ({ integrationStatus }) => {
  var className = "integration-status-container";

  if (integrationStatus === INTEGRATION_NOT_CONNECTED) {
    className += " integration-status-not-connected";
  }

  if (integrationStatus === INTEGRATION_CONNECTED) {
    className += " integration-status-connected";
  }

  if (integrationStatus === INTEGRATION_PENDING) {
    className += " integration-status-pending";
  }

  return <div className={className}>{integrationStatus}</div>;
};

export default IntegrationStatus;
