const SlimButtonWithImage = ({ image, title, action }) => {
  var className = "simple-button-with-image";

  return (
    <button className={className} onClick={action}>
      <img src={image} className="image" alt="button" />
      <span className="title">{title}</span>
    </button>
  );
};

export default SlimButtonWithImage;
