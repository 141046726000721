import {
  PRICE_ANNUALLY,
  PRICE_MONTHLY,
} from "../../../constants/SiteConstants";
import { ReactComponent as CheckSvg } from "../../../resources/img/check-arrow.svg";
import { PricingCardButton } from "./PricingCardButton";
export const PricingPlanCard = ({
  planType,
  planPrice,
  planPeriod,
  checkList,
  secondaryCard,
  buttonTitle,
  action,
}) => {
  var titleClassName = "pricing-card-type";

  if (secondaryCard) {
    titleClassName += " pricing-card-type-secondary";
  }

  const mapPlanPeriod = (plan_period) => {
    if (plan_period === PRICE_MONTHLY) {
      return "month";
    }
    if (plan_period === PRICE_ANNUALLY) {
      return "year";
    }
  };

  return (
    <div className="pricing-card">
      <h1 className={titleClassName}>{planType}</h1>
      {secondaryCard == false ? (
        <h2 className="pricing-card-price">{planPrice}</h2>
      ) : (
        <h2 className="pricing-card-price">
          {planPrice}
          {/* <span>/{mapPlanPeriod(planPeriod)}</span> */}
        </h2>
      )}

      <PricingCardButton
        secondaryButton={secondaryCard}
        title={buttonTitle}
        action={action}
      />
      <div className="pricing-card-check-list">
        {checkList?.map((item, index) => (
          <div className="pricing-card-check-item">
            <CheckSvg />
            <p
              className={
                index < 3
                  ? "pricing-card-check-item-text pricing-card-check-item-text-bold"
                  : "pricing-card-check-item-text"
              }
            >
              {item}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
