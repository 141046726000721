import { useEffect, useState } from "react";
import PlatformHeader from "../../header/PlatformHeader";
import { ANDROID, IOS, WEB } from "../../../../constants/PlatformConstants";
import AffectedUsersGraph from "./AffectedUsersGraph";
import DateRangePicker from "./DateRangePicker";
import { RANGE_30_DAYS } from "./DateRangePicker";
import UsersOnAppVersionsGraph from "./UsersOnAppVersionsGraph";
import TypeComponent, { BLOCK, NOTIFY, UPDATE } from "../../generic/Type";
import ConfigureApp from "./ConfigureApp";
import SectionTitle from "../../generic/DashboardSectionTitle";
import { appForType } from "./DashboardHelper";
import { getRulesAPICall } from "../../../model/api/MessagesService";
import Notifications from "../../../../helpers/Notifications";
import {
  affectedUsersAPICall,
  usersOnAppAPICall,
} from "../../../model/api/DashboardService";
import { ActionButton } from "../../../generic/ActionButton";
import CreateAppMessage from "../messages/create/CreateAppMessage";
import ConfigureIntegrationAndroid from "../integrations/configure/ConfigureIntegrationAndroid";
import ConfigureIntegrationIos from "../integrations/configure/ConfigureIntegrationIos";
import ConfigureIntegrationWeb from "../integrations/configure/ConfigureIntegrationWeb";

const UsersOnAppVersion = ({
  selectedFilter,
  appVersionsUsers,
  selectedRange,
  setSelectedRange,
}) => {
  const datePicker = (
    <DateRangePicker
      selectedRange={selectedRange}
      setSelectedRange={setSelectedRange}
    />
  );

  var title = "Users on app versions";
  if (selectedFilter === WEB) {
    title = "Users on current version";
  }

  const graph = <UsersOnAppVersionsGraph appVersionsUsers={appVersionsUsers} />;

  return (
    <div className="users-on-app-version">
      <SectionTitle title={title} />
      <div className="affected-users-container">{[datePicker, graph]}</div>
    </div>
  );
};

const AffectedUsersContainer = ({
  affectedUsers,
  selectedRange,
  setSelectedRange,
}) => {
  const datePicker = (
    <DateRangePicker
      selectedRange={selectedRange}
      setSelectedRange={setSelectedRange}
    />
  );

  const graph = <AffectedUsersGraph affectedUsers={affectedUsers} />;

  return (
    <div className="affected-users">
      <SectionTitle title={"Affected users"} />
      <div className="affected-users-container">{[datePicker, graph]}</div>
    </div>
  );
};

const LastMessageRow = ({
  version,
  title,
  message,
  affected,
  status,
  selectedFilter,
}) => {
  var versionRow = <td className="table-version"></td>;
  if (selectedFilter !== WEB) {
    versionRow = (
      <td className="table-version">
        <div className="version-block">
          <div className="text">{version}</div>
        </div>
      </td>
    );
  }

  return (
    <tr className="dashboard-last-message-row">
      {versionRow}
      <td className="table-title">{title}</td>
      <td className="table-message">{message}</td>
      <td className="table-affected">{affected}</td>
      <td className="table-type">
        <TypeComponent type={status} />
      </td>
    </tr>
  );
};

const LastMessagesContainer = ({ goToMessages, messages, selectedFilter }) => {
  var rows = [];
  messages.forEach((element) => {
    const item = (
      <LastMessageRow
        version={element.version}
        title={element.title}
        message={element.message}
        affected={""}
        status={element.rule_type}
        selectedFilter={selectedFilter}
      />
    );

    rows.push(item);
  });

  var versionRow = <th className="table-version"></th>;
  if (selectedFilter !== WEB) {
    versionRow = <th className="table-version">VERSION</th>;
  }

  return (
    <div className="last-messages">
      <div className="header">
        <div className="title">
          <SectionTitle title={"Last messages"} />
        </div>

        <div className="see-all">
          <button
            className="simple-button-text"
            onClick={() => {
              goToMessages();
            }}
          >
            See all messages →
          </button>
        </div>
      </div>
      <div className="table-container">
        <table className="platform-table">
          <tr>
            {versionRow}
            <th className="table-title">TITLE</th>
            <th className="table-message">MESSAGE</th>
            <th className="table-affected"></th>
            <th className="table-type">TYPE</th>
          </tr>

          {rows}
        </table>
      </div>
    </div>
  );
};

const Dashboard = ({
  setLoading,
  selectedProject,
  goToIntegrations,
  goToMessages,
  reloadProject,
  selectedFilter,
  setSelectedFilter,
}) => {
  const [usersOnAppSelectedRange, setUsersOnAppSelectedRange] =
    useState(RANGE_30_DAYS);
  const [affectedUsersSelectedRange, setAffectedUsersSelectedRange] =
    useState(RANGE_30_DAYS);
  const [messages, setMessages] = useState([]);
  const [affectedUsers, setAffectedUsers] = useState(null);
  const [appVersionsUsers, setAppVersionsUsers] = useState(null);
  const [shouldDisplayAddMessage, setShouldDisplayAddMessage] = useState(false);
  const [integrationContainer, setIntegrationContainer] = useState(null);

  const title = "Dashboard";
  const showFilters = true;

  const fetchMessages = () => {
    if (!selectedProject) {
      return;
    }

    setLoading(true);
    getRulesAPICall(
      selectedProject.id,
      "",
      false,
      selectedFilter,
      (response) => {
        setLoading(false);

        const messages = response.data.rules;
        var sortedMessages = messages.sort((a, b) =>
          a["start"] > b["start"] ? 1 : -1
        );

        setMessages(sortedMessages.slice(0, 5));
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const fetchAffectedUsers = () => {
    if (!selectedProject) {
      return;
    }

    setLoading(true);
    affectedUsersAPICall(
      selectedFilter,
      selectedProject.id,
      affectedUsersSelectedRange,
      (response) => {
        setLoading(false);
        setAffectedUsers(response.data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const fetchUsersOnAppVersions = () => {
    if (!selectedProject) {
      return;
    }

    setLoading(true);
    usersOnAppAPICall(
      selectedFilter,
      selectedProject.id,
      usersOnAppSelectedRange,
      (response) => {
        console.log("response", response);
        setLoading(false);
        setAppVersionsUsers(response.data.app_versions_users);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const fetchAll = () => {
    fetchMessages();
    fetchAffectedUsers();
    fetchUsersOnAppVersions();
  };

  useEffect(() => {
    fetchAll();
  }, [selectedFilter, selectedProject]);

  useEffect(() => {
    fetchAffectedUsers();
  }, [affectedUsersSelectedRange]);

  useEffect(() => {
    fetchUsersOnAppVersions();
  }, [usersOnAppSelectedRange]);

  useEffect(() => {
    fetchAll();
  }, []);

  const header = (
    <PlatformHeader
      title={title}
      showFilters={showFilters}
      selectedFilter={selectedFilter}
      hasSelectedFilter={setSelectedFilter}
    />
  );

  const content = (
    <div className="master-content container">
      <div className="row">
        <div className="col affected-users">
          <AffectedUsersContainer
            affectedUsers={affectedUsers}
            selectedRange={affectedUsersSelectedRange}
            setSelectedRange={setAffectedUsersSelectedRange}
          />
        </div>
        <div className="col users-on-app-versions">
          <UsersOnAppVersion
            selectedFilter={selectedFilter}
            appVersionsUsers={appVersionsUsers}
            selectedRange={usersOnAppSelectedRange}
            setSelectedRange={setUsersOnAppSelectedRange}
          />
        </div>
      </div>

      <div className="row">
        {messages.length == 0 ? (
          <div className="col-4 affected-users ml-10">
            <ActionButton
              title={"Add your first message"}
              action={() => {
                setShouldDisplayAddMessage(true);
              }}
              className="mt-48"
              isSlim={true}
            />
          </div>
        ) : (
          <div className="col flex-column-reverse">
            <LastMessagesContainer
              goToMessages={goToMessages}
              messages={messages}
              selectedFilter={selectedFilter}
            />
          </div>
        )}
      </div>
    </div>
  );

  const goToConfigureApp = () => {
    var integrationContainer;

    const dismiss = () => {
      setIntegrationContainer(null);
      reloadProject();
      fetchAll();
    };

    if (selectedFilter === IOS) {
      integrationContainer = (
        <ConfigureIntegrationIos
          setLoading={setLoading}
          dismissAction={dismiss}
          selectedProject={selectedProject}
        />
      );
    }

    if (selectedFilter === ANDROID) {
      integrationContainer = (
        <ConfigureIntegrationAndroid
          setLoading={setLoading}
          dismissAction={dismiss}
          selectedProject={selectedProject}
        />
      );
    }

    if (selectedFilter === WEB) {
      integrationContainer = (
        <ConfigureIntegrationWeb
          setLoading={setLoading}
          dismissAction={dismiss}
          selectedProject={selectedProject}
        />
      );
    }

    if (integrationContainer) {
      setIntegrationContainer(integrationContainer);
    }
  };

  var appName = "iOS";
  if (selectedFilter === ANDROID) {
    appName = "Android";
  }

  if (selectedFilter === WEB) {
    appName = "Web";
  }

  const configureApp = (
    <ConfigureApp
      app={appName}
      isWeb={selectedFilter === WEB}
      configureAction={goToConfigureApp}
    />
  );

  var contentToDisplay = content;

  if (!appForType(selectedFilter, selectedProject)) {
    contentToDisplay = configureApp;
  }

  var finalContent = (
    <div id="dashboard">
      {header}
      {contentToDisplay}
    </div>
  );

  if (shouldDisplayAddMessage) {
    finalContent = (
      <CreateAppMessage
        selectedProject={selectedProject}
        setLoading={setLoading}
        cancelAction={() => {
          setShouldDisplayAddMessage(false);
          fetchMessages();
        }}
        platform={selectedFilter}
      />
    );
  }

  if (integrationContainer) {
    finalContent = integrationContainer;
  }

  return finalContent;
};

export default Dashboard;
