import blogPostImage from "../../../resources/img/blog/force-update-ios.png";

import newAppImage from "../../../resources/img/blog/new-app.png";
import code from "../../../resources/img/blog/ios-code.png";
import force from "../../../resources/img/blog/force.png";
import configure from "../../../resources/img/blog/configure-ios.png";

const HowToForceAnUpdate = () => {
  const href = "/blog/how-to-force-your-users-to-update-your-iOS-app";
  const title = "How to force your users to update your iOS app?";
  const readTime = "4";
  const shortDescription = `Semaphr allows you to force update your iOS app and manage your app versions easily.`;

  const content = (
    <div>
      <p>
        There's no easy way to force users to update their iOS app. Apple does
        not provide any mechanisms for it, so this needs to be handled by app
        developers.
      </p>
      <p>
        Implementing the mechanism from scratch is a lengthy, error-prone
        process, fortunately, there's a simple solution, by integrating the
        Semaphr SDK, you'll be able to force an update to your users or to
        encourage them to update while still letting them use the old version.
        So how do you do it?
      </p>

      <h2>1. Register for semaphr</h2>
      <div className="pt-60 pb-60">
        <img src={newAppImage} className="blog-image" alt="new app" />
      </div>
      <p>
        The first step is{" "}
        <a href="https://semaphr.com/register" target="blank">
          creating an account
        </a>{" "}
        and adding a new project.
      </p>
      <p>
        Semaphr, allows you to bundle all your apps in a single account, so if
        you have multiple apps, you can add them all.
      </p>
      <p>
        A project has an iOS and Android application, so if you support both
        platforms with your digital product, you can add them both.
      </p>
      <div className="pt-60 pb-60">
        <img src={configure} className="blog-image" alt="new app" />
      </div>
      <h2>2. Integrate the SDK</h2>
      <p>
        Integrate the SDK, go to <b>Settings -> Integrations</b>, fill in the
        details of your app, and follow the instructions.
      </p>
      <p>
        Copy the bundle ID from XCode by selecting your target and clicking the
        General tab. Bundle Identifier is found under Identity.
      </p>
      <h3>Integrate using SPM</h3>
      <p>
        If you're using SPM, copy the SPM project link and past it into Xcode's
        SPM module finder, more details on how to integrate an SPM package can
        be found{" "}
        <a
          href="https://developer.apple.com/documentation/xcode/adding-package-dependencies-to-your-app"
          target="blank"
        >
          here
        </a>
        .
      </p>
      <h3>Integrate using Cocoapods</h3>
      <p>
        If you're using Cocoapods, copy the pod name and add it to the Podfile,
        and install the new pods, more details can be found{" "}
        <a
          href="https://guides.cocoapods.org/using/using-cocoapods.html"
          target="blank"
        >
          here
        </a>
        .
      </p>
      <h3>Initialization code</h3>
      <div className="pt-60 pb-60">
        <img src={code} className="blog-image" alt="new app" />
      </div>
      <p>
        Add the initialization code, and copy the initialization code (
        <b>
          it will automatically be copied with your API key if you press the
          "Copy to Clipboard" button
        </b>
        ), and add it to your app.
      </p>
      <p>
        Usually, this is added to the AppDelegate, or if you prefer add it to
        your Scene delegate.
      </p>
      <p>Save your integration and run the app.</p>
      <h2 id="three">3. Force an update</h2>
      <div className="pt-60 pb-60">
        <img src={force} className="blog-image" alt="new app" />
      </div>
      <p>
        To force an update on your users you need to go to <b>messages</b> and
        add a new one with the message type <b>"UPDATE"</b>.
      </p>
      <p>
        Press <b>"Add a new message"</b>, and fill in all the details. Next to
        the form you can see a preview of the message as it will be displayed on
        your users' phones.
      </p>
      <h3>Title & description</h3>
      <p>You need to fill in a message title and a message description.</p>
      <p>
        In the details sections, <b>you can add links</b>, that are going to be
        clickable and will take users to their default web browsers to the link
        you've specified.
      </p>
      <h3>Message type</h3>
      <p>
        As a message type, select <b>Update</b>. By selecting this message type,
        the app will display an update button that will take the users directly
        to the AppStore so they can update the app to their latest version even
        if they have automatic updates disabled.
      </p>
      <p className="important-p">
        The update message has an <b>"Allow skipping"</b> option, if that is
        enabled, a close button will also be displayed to the users, so they can
        continue to use their current version of the app without updating.
      </p>
      <h3>Versions</h3>
      <p>Here you need to select a range (= or &lt;) and an app version.</p>
      <p>
        If you select Exact (=) that means only that specific version will be
        affected by the message. If you select (&lt; Lower than), all the
        versions lower than the selected one will be affected.
      </p>
      <p>
        To illustrate this let's say your app has the following versions: 1.0.0,
        1.0.1, 1.2.0, 1.3.0.
      </p>
      <p>
        If you add an <b>Exact rule (=) for version 1.2.0</b>, your message will
        only be displayed to the users of the app which are on version 1.2.0.
        Other versions will remain unaffected.
      </p>
      <p>
        If you add a rule for <b>Lower than (&lt;) for version 1.2.0</b>, your
        message will apply to all the versions lower than 1.2.0 -- in this
        example 1.0.0, 1.0.1. Other versions will remain unaffected.
      </p>
      <p className="important-p">
        If you just implemented the SDK and you don't see your app version, that
        means, you have NOT started the app with the SDK integrated, or there
        are issues with the integration. In this case, check if the SDK is
        integrated properly -- if something is wrong, you'll see error messages
        in the console log.
      </p>

      <h3>Schedule</h3>
      <p>
        You need to pick a start date and time for your rule, and optionally and
        end date. Your message will only be displayed to your users in the
        selected timeframe, if you want to add a message for an indefinite
        amount of time, select your desired start date and leave the end date
        empty.
      </p>

      <h3>Publish</h3>
      <p>
        Once you click <b>Publish</b> the new message is added and your users
        will start seeing it when bringing the app to the foreground.
      </p>

      <p>
        Should you wish to disable the message, simply go to <b>Messages</b>,
        click on your desired message, and remove it, once a message is removed,
        it will have no effect on the users.
      </p>
      <br></br>
      <br></br>
      <p>
        For more details about the integrations, and the iOS SDK check the{" "}
        <a href="https://github.com/semaphr/ios" target="blank">
          documentation
        </a>
        , or if you have any questions write us an email at{" "}
        <a href="mailto:info@semaphr.com">info@semaphr.com</a>.
      </p>
    </div>
  );

  return {
    title: title,
    content: content,
    href: href,
    headerImage: blogPostImage,
    readTime: readTime,
    shortDescription: shortDescription,
  };
};

export default HowToForceAnUpdate;
