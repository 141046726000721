import closeIcon from "../../../resources/img/close-small.svg";
import warningIcon from "../../../resources/img/warning-icon.svg";
import destructionIcon from "../../../resources/img/destruction-icon.svg";

const PopupMessage = ({
  title,
  subtitle,
  actionTitle,
  action,
  open,
  setOpen,
  destructive = false,
}) => {
  const closeItem = () => {
    setOpen(false);
  };

  const executeAction = () => {
    setOpen(false);
    action();
  };

  var popupClassName = "popup-container";
  var icon = warningIcon;
  var actionButtonClassName = "transparent-button popup-action-button";
  if (destructive) {
    popupClassName += " popup-destructive";
    icon = destructionIcon;
    actionButtonClassName += " popup-action-button-destructive";
  }

  var content;

  if (open) {
    content = (
      <div class="popup-message">
        <div className={popupClassName}>
          <div className="content">
            <div className="header">
              <div className="close-item" onClick={() => closeItem()}>
                <img src={closeIcon} className="popup-close-icon" alt="close" />
              </div>
            </div>
            <div className="title-container">
              <div className="image">
                <img src={icon} className="popup-message-icon" alt="notify" />
              </div>
              <div className="title">{title}</div>
            </div>
            <div className="description-container">{subtitle}</div>
          </div>
          <div className="footer-container">
            <div className="cancel-action">
              <button
                className="transparent-button popup-cancel-button"
                onClick={() => closeItem()}
              >
                Cancel
              </button>
            </div>
            <div className="action">
              <button
                className={actionButtonClassName}
                onClick={() => executeAction()}
              >
                {actionTitle}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default PopupMessage;
