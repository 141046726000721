import { useState } from "react";
import Dashboard from "./dashoard/Dashboard";
import Messages from "./messages/Messages";
import CreateAppMessage from "./messages/create/CreateAppMessage";
import Members from "./members/Members";
import Integrations from "./integrations/Integrations";
import ConfigureIntegrationIos from "./integrations/configure/ConfigureIntegrationIos";
import Profile from "./profile/Profile";
import Subscriptions from "./subscriptions/Subscriptions";
import CreateNewProjectPage from "../../../pages/authentication/CreateNewProjectPage";
import { IOS, ANDROID, WEB } from "../../../constants/PlatformConstants";

import {
  MENU_DASHBOARD,
  MENU_INTEGRATIONS,
  MENU_MEMBERS,
  MENU_MESSAGES,
  MENU_PROFILE,
  MENU_SETTINGS,
  MENU_SUBSCRIPTIONS,
} from "../../../constants/SideBarConstants";

const PlatformDetails = ({
  setLoading,
  selectedMenu,
  selectedProject,
  refreshProjects,
  setSelectedMenu,
}) => {
  var content;

  const [selectedFilter, setSelectedFilter] = useState(IOS);

  if (selectedMenu === MENU_DASHBOARD) {
    content = (
      <Dashboard
        setLoading={setLoading}
        selectedProject={selectedProject}
        goToIntegrations={() => {
          setSelectedMenu(MENU_INTEGRATIONS);
        }}
        goToMessages={() => {
          setSelectedMenu(MENU_MESSAGES);
        }}
        reloadProject={refreshProjects}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
    );
  }

  if (selectedMenu === MENU_INTEGRATIONS) {
    content = (
      <Integrations
        setLoading={setLoading}
        selectedProject={selectedProject}
        reloadProject={refreshProjects}
      />
    );
  }

  if (selectedMenu === MENU_MEMBERS) {
    content = (
      <Members
        setLoading={setLoading}
        selectedProject={selectedProject}
        refreshProjects={refreshProjects}
      />
    );
  }

  if (selectedMenu === MENU_MESSAGES) {
    content = (
      <Messages
        setLoading={setLoading}
        selectedProject={selectedProject}
        goToConfigureApp={() => {
          setSelectedMenu(MENU_INTEGRATIONS);
        }}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
    );
  }

  if (selectedMenu === MENU_PROFILE) {
    content = (
      <Profile setLoading={setLoading} selectedProject={selectedProject} />
    );
  }

  if (selectedMenu === MENU_SUBSCRIPTIONS) {
    content = (
      <Subscriptions
        setLoading={setLoading}
        selectedProject={selectedProject}
      />
    );
  }

  return <div className="platform-detail">{content}</div>;
};

export default PlatformDetails;
