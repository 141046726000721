import penIcon from "../../../resources/img/pen.svg";

const WhiteInputField = ({
  placeholder,
  showEditIcon,
  readOnly = false,
  value = null,
  extraClass = null,
  onChange = null,
  id = null,
}) => {
  var image;
  if (showEditIcon && !readOnly) {
    image = (
      <div className="image">
        <img src={penIcon} className="pen-icon" alt="pen" />
      </div>
    );
  }

  const emptyOnChange = ({ val }) => {};

  if (onChange == null) {
    onChange = emptyOnChange;
  }

  var className = "white-input-field-container";
  if (extraClass) {
    className += " " + extraClass;
  }

  return (
    <div className={className}>
      <div className="input">
        <input
          id={id}
          className="white-input-field"
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        ></input>
      </div>
      {image}
    </div>
  );
};

export default WhiteInputField;
