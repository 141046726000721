import React from "react";
import Decoration from "../../../resources/img/see-in-action-page-decoration.svg";
import SeeItInActionDesktop from "../../../resources/img/see-in-action-desktop.png";
import $ from "jquery";

const SeeInActionPage = () => {
  const playVideo = () => {
    var elem = document.getElementById("video");
    elem.currentTime = 0;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    elem.play();

    $("video").on(
      "fullscreenchange webkitfullscreenchange mozfullscreenchange",
      function () {
        var elem = document.getElementById("video");
        if (elem.currentTime === 0) {
          elem.play();
        } else {
          elem.pause();
        }
      }
    );
  };

  return (
    <div className="see-in-action-page">
      <video controls id="video" allowfullcreen="true">
        <source
          src="https://designesy.s3.eu-north-1.amazonaws.com/semaphr/semaphr-video.mov"
          type="video/mp4"
        ></source>
      </video>
      <div className="container">
        <div className="display-on-big">
          <div className="sia-decoration">
            <img src={Decoration} />
            <div className=" d-flex">
              <button
                onClick={playVideo}
                className="invisible-button see-in-action-container"
              >
                <div className="d-flex see-in-action-image">
                  <img src={SeeItInActionDesktop} />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="display-on-small">
          <div className=" d-flex">
            <button
              onClick={playVideo}
              className="invisible-button see-in-action-container-small"
            >
              <div className="d-flex see-in-action-image-small">
                <img src={SeeItInActionDesktop} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeInActionPage;
