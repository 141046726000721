import { useEffect, useState, useRef } from "react";
import PlatformHeader from "../../header/PlatformHeader";
import { IOS, WEB } from "../../../../constants/PlatformConstants";
import TotalMessages from "./TotalMessages";
import plusImage from "../../../../resources/img/plus.svg";
import SlimButtonWithImage from "../../generic/SimpleButtonWithImage";
import SearchBar from "./SearchBar";
import AllArchiveSelector from "./AllArchiveSelector";
import { SELECTOR_ALL, SELECTOR_ARCHIVED } from "./AllArchiveSelector";
import TypeComponent, { BLOCK, NOTIFY, UPDATE } from "../../generic/Type";
import { format } from "date-fns";

import rightArrowImage from "../../../../resources/img/right-arrow.svg";
import sortImage from "../../../../resources/img/sort.svg";
import { getRulesAPICall } from "../../../model/api/MessagesService";
import Notifications from "../../../../helpers/Notifications";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import AddMessage from "./AddMessage";
import CreateAppMessage from "./create/CreateAppMessage";
import CreateWebMessage from "./create/CreateWebMessage";
import EditAppMessage from "./create/EditAppMessage";
import EditWebAppMessage from "./create/EditWebAppMessage";
import { appForType } from "../dashoard/DashboardHelper";
import ConfigureApp from "../dashoard/ConfigureApp";
import { ANDROID } from "../../../../constants/PlatformConstants";

const MESSAGES_DATE_FORMAT = "MM.DD.yyyy";
const MESSAGES_HOUR_FORMAT = "H:mm a";

const MeesagesSearchAndFilter = ({
  searchTerm,
  setSearchTerm,
  selector,
  setSelector,
}) => {
  return (
    <div className="messages-search-and-filter platform-margins">
      <SearchBar term={searchTerm} setTerm={setSearchTerm} />
      <AllArchiveSelector selectedKey={selector} setSelectedKey={setSelector} />
    </div>
  );
};

const MessageListHeader = ({ isWeb, sortElementByKey }) => {
  var item = <th className="table-version-messages"></th>;
  if (!isWeb) {
    item = <th className="table-version-messages">VERSION</th>;
  }

  return (
    <tr>
      {item}
      <th className="table-message">MESSAGE</th>
      <th className="table-type">TYPE</th>
      <th
        className="table-date"
        onClick={() => {
          sortElementByKey("start");
        }}
      >
        START DATE <img src={sortImage} className="sort-img" alt="sort" />
      </th>
      <th
        className="table-date"
        onClick={() => {
          sortElementByKey("end");
        }}
      >
        END DATE <img src={sortImage} className="sort-img" alt="sort" />
      </th>
      <th className="table-action">ACTIONS</th>
    </tr>
  );
};

const MessageListRow = ({
  version,
  title,
  message,
  status,
  startDate,
  endDate,
  isWeb,
  selectAction,
}) => {
  var startDateText = "";
  if (startDate) {
    startDateText =
      moment(startDate).format(MESSAGES_DATE_FORMAT) +
      "\n" +
      moment(startDate).format(MESSAGES_HOUR_FORMAT);
  }

  var endDateDateText = "";
  if (endDate) {
    endDateDateText =
      moment(endDate).format(MESSAGES_DATE_FORMAT) +
      "\n" +
      moment(endDate).format(MESSAGES_HOUR_FORMAT);
  }

  var versionContainer;
  if (!isWeb) {
    versionContainer = (
      <div className="version-block">
        <div className="text">{version}</div>
      </div>
    );
  }

  return (
    <tr
      className="message-table-view-row"
      onClick={() => {
        selectAction();
      }}
    >
      <td className="table-version-messages">{versionContainer}</td>
      <td className="table-message-and-title">
        <div className="title">{title}</div>
        <div className="message">{message}</div>
      </td>
      <td className="table-type">
        <TypeComponent type={status} />
      </td>

      <td className="table-date">{startDateText}</td>
      <td className="table-date">{endDateDateText}</td>
      <td className="table-action">
        <img
          src={rightArrowImage}
          className="right-arrow-messages"
          alt="arrow"
        />
      </td>
    </tr>
  );
};

const MessagesList = ({ messages, isWeb, sortElementByKey, selectAction, isArchived }) => {
  var messagesList = [];
  messages.forEach((element) => {
    const item = (
      <MessageListRow
        version={element.version}
        title={element.title}
        message={element.message}
        status={element.rule_type}
        startDate={element.start}
        endDate={element.end}
        isWeb={isWeb}
        isArchived={isArchived}
        selectAction={() => {
          selectAction(element);
        }}
      />
    );

    messagesList.push(item);
  });

  return (
    <div className="messages-list-container platform-margins">
      <div className="table-container">
        <table className="platform-table">
          <MessageListHeader
            isWeb={isWeb}
            sortElementByKey={sortElementByKey}
          />
          {messagesList}
        </table>
      </div>
    </div>
  );
};

const Messages = ({
  setLoading,
  selectedProject,
  goToConfigureApp,
  selectedFilter,
  setSelectedFilter,
}) => {
  const [selector, setSelector] = useState(SELECTOR_ALL);
  const [searchTerm, setSearchTerm] = useState(null);
  const asc = useRef(true);
  const [hasMessages, setHasMessages] = useState(false);

  const [addMessageContainer, setAddMessageContainer] = useState(null);

  const title = "Messages";
  const showFilters = true;

  const header = (
    <PlatformHeader
      title={title}
      showFilters={showFilters}
      selectedFilter={selectedFilter}
      hasSelectedFilter={setSelectedFilter}
    />
  );

  const createMessageAction = () => {
    if (!isAppConnected()) {
      Notifications.showError(
        "To add a message, you must first connect your app, integrate the SDK, and run the app at least once."
      );

      return;
    }
    var content = (
      <CreateAppMessage
        selectedProject={selectedProject}
        setLoading={setLoading}
        cancelAction={() => {
          setAddMessageContainer(null);
          fetchMessages();
        }}
        platform={selectedFilter}
      />
    );

    if (selectedFilter === WEB) {
      content = (
        <CreateWebMessage
          selectedProject={selectedProject}
          setLoading={setLoading}
          cancelAction={() => {
            setAddMessageContainer(null);
            fetchMessages();
          }}
          platform={selectedFilter}
        />
      );
    }
    setAddMessageContainer(content);
  };

  const isAppConnected = () => {
    if (selectedFilter === IOS && selectedProject.ios_application) {
      return selectedProject.ios_application.connected;
    }

    if (selectedFilter === ANDROID && selectedProject.android_application) {
      return selectedProject.android_application.connected;
    }

    return false;
  };

  const goToMessage = (message) => {
    var content = (
      <EditAppMessage
        selectedProject={selectedProject}
        setLoading={setLoading}
        cancelAction={() => {
          setAddMessageContainer(null);
          fetchMessages();
        }}
        platform={selectedFilter}
        message={message}
      />
    );

    if (selectedFilter === WEB) {
      content = (
        <EditWebAppMessage
          selectedProject={selectedProject}
          setLoading={setLoading}
          cancelAction={() => {
            setAddMessageContainer(null);
            fetchMessages();
          }}
          platform={selectedFilter}
          message={message}
        />
      );
    }
    setAddMessageContainer(content);
  };

  const [messages, setMessages] = useState([]);

  const sortElementByKey = (values, key) => {
    const trueValue = asc.current ? 1 : -1;
    const falseValue = asc.current ? -1 : 1;

    var newMessages = values.sort((a, b) =>
      a[key] > b[key] ? trueValue : falseValue
    );

    var mess = cloneDeep(newMessages);

    asc.current = !asc.current;
    setMessages(mess);
  };

  const fetchMessages = () => {
    const archived = selector === SELECTOR_ARCHIVED;

    setLoading(true);
    getRulesAPICall(
      selectedProject.id,
      searchTerm,
      archived,
      selectedFilter,
      (response) => {
        setLoading(false);
        asc.current = true;
        setHasMessages(response.data.has_rules);
        sortElementByKey(response.data.rules, "start");
      },
      (error) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  let total = messages.length;
  let block = messages.filter((m) => m.rule_type === BLOCK).length;
  let update = messages.filter((m) => m.rule_type === UPDATE).length;
  let notify = messages.filter((m) => m.rule_type === NOTIFY).length;

  const subHeader = (
    <div className="messages-subheader">
      <div className="messages">
        <TotalMessages
          total={total}
          block={block}
          update={update}
          notify={notify}
        />
      </div>

      <div className="button">
        <SlimButtonWithImage
          image={plusImage}
          title={"Create message"}
          action={createMessageAction}
        />
      </div>
    </div>
  );

  const searchAndFilter = (
    <MeesagesSearchAndFilter
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      selector={selector}
      setSelector={setSelector}
    />
  );

  const messagesList = (
    <MessagesList
      messages={messages}
      isWeb={selectedFilter === WEB}
      isArchived={selector === SELECTOR_ARCHIVED}
      sortElementByKey={(key) => {
        sortElementByKey(messages, key);
      }}
      selectAction={goToMessage}
    />
  );

  var configureApp = (
    <AddMessage
      app={selectedFilter}
      isWeb={selectedFilter === WEB}
      configureAction={createMessageAction}
    />
  );

  var appName = "iOS";
  if (selectedFilter === ANDROID) {
    appName = "Android";
  }

  if (selectedFilter === WEB) {
    appName = "Web";
  }

  const configureApplication = (
    <ConfigureApp
      app={appName}
      isWeb={selectedFilter === WEB}
      configureAction={goToConfigureApp}
    />
  );

  var messagesContent = [header, subHeader, searchAndFilter, messagesList];

  var currentContent = messagesContent;

  if (!hasMessages && selector !== SELECTOR_ARCHIVED) {
    currentContent = [header, configureApp];
  }

  const app = appForType(selectedFilter, selectedProject);
  if (!app) {
    currentContent = [header, configureApplication];
  }

  if (addMessageContainer) {
    currentContent = addMessageContainer;
  }

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    fetchMessages();
  }, [searchTerm, selector, selectedFilter, selectedProject]);

  return <div id="messages">{currentContent}</div>;
};

export default Messages;
