import iosImage from "../../../resources/img/ios.svg";
import webImage from "../../../resources/img/web.svg";
import androidImage from "../../../resources/img/android.svg";

export const PLATFORM_WEB = "Web";
export const PLATFORM_IOS = "iOS";
export const PLATFORM_ANDROID = "Android";

export const INTEGRATION_NOT_CONNECTED = "NOT CONNECTED";
export const INTEGRATION_CONNECTED = "CONNECTED";
export const INTEGRATION_PENDING = "PENDING";

export const imageForPlatform = (platform) => {
  if (platform === PLATFORM_WEB) {
    return webImage;
  }

  if (platform === PLATFORM_IOS) {
    return iosImage;
  }

  if (platform === PLATFORM_ANDROID) {
    return androidImage;
  }
};

export const getIntegrationStatusForApp = (app) => {
  if (!app) {
    return INTEGRATION_NOT_CONNECTED;
  }

  if (app.connected === false) {
    return INTEGRATION_PENDING;
  }

  return INTEGRATION_CONNECTED;
};

export const getIdentifierForApp = (app) => {
  if (!app) {
    return "";
  }

  return app.identifier;
};
