import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import React from "react";

const TrackingComponent = () => {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return <></>;
};

export default TrackingComponent;
