import { SimpleButton } from "../../generic/SimpleButton";
import SelectorButton from "../details/messages/SelectorButton";

export const SELECTOR_ALL = "all";
export const SELECTOR_ARCHIVED = "archived";

const GenericSelector = ({
  extraClassName,
  values,
  selectedValue,
  setSelectedValue,
}) => {
  let options = [];

  values.forEach((value) => {
    options.push(
      <SelectorButton
        isSelected={selectedValue === value}
        setSelected={() => {
          setSelectedValue(value);
        }}
        title={value}
      />
    );
  });

  return <div className={extraClassName}>{options}</div>;
};

export default GenericSelector;
