const MessageBlock = ({ title, number, className = null }) => {
  var blockClassName = "message-block";
  if (className) {
    blockClassName += " " + className;
  }

  return (
    <div className={blockClassName}>
      <div className="title">{title}</div>
      <div className="number">{number}</div>
    </div>
  );
};

const TotalMessages = ({ total, block, update, notify }) => {
  return (
    <div className="total-messages-component container">
      <div className="row">
        <div className="col total-col">
          <MessageBlock title={"TOTAL MESSAGES"} number={total} />
        </div>
        <div className="col">
          <MessageBlock title={"BLOCK"} number={block} />
        </div>
        <div className="col">
          <MessageBlock title={"UPDATE"} number={update} />
        </div>
        <div className="col">
          <MessageBlock
            title={"NOTIFY"}
            number={notify}
            className={"message-block-no-border"}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalMessages;
