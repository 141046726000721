import { useState } from "react";
import PlatformHeader from "../../../header/PlatformHeader";
import SectionTitle from "../../../generic/DashboardSectionTitle";
import WhiteInputField from "../../../generic/WhiteInputField";

import { SimpleButton } from "../../../../generic/SimpleButton";

import iosImage from "../../../../../resources/img/ios.svg";
import webImage from "../../../../../resources/img/web.svg";
import androidImage from "../../../../../resources/img/android.svg";

import PlatformType from "./elements/PlatformType";
import CopyField from "../../../generic/CopyField";
import { ActionButton } from "../../../../generic/ActionButton";
import GenericSelector from "../../../generic/GenericSelector";
import { isIdentifierValid } from "../../../../../helpers/ValidatorHelper";
import {
  addApplicationAPICall,
  editApplicationAPICall,
  deleteApplicationAPICall,
} from "../../../../model/api/ApplicationsService";
import Notifications from "../../../../../helpers/Notifications";
import IntegrationStatus from "../elements/IntegrationStatus";
import { getIntegrationStatusForApp } from "../../../generic/PlatformHelper";
import { DestructiveButton } from "../../../../generic/DestructiveButton";
import PopupMessage from "../../../generic/PopupMessage";

const gradleCodeToCopy = () => {
  return "implementation 'com.semaphr:Semaphr:1.0'";
};

const reactCodeToCopy = () => {
  return "react install";
};

const sdkCodeToCopy = (project) => {
  return 'Semaphr.configure(this, "' + project.api_key + '")';
};

const GradleCode = () => {
  return (
    <div>
      <span className="code-highlighted">implementation</span>{" "}
      'com.semaphr:Semaphr:1.0'
    </div>
  );
};

const ReactCode = () => {
  return (
    <div>
      <span className="code-highlighted">react </span>'KillSwitch'
    </div>
  );
};

const SDKCode = () => {
  return (
    <div>
      <span className="code-highlighted">import</span> com.semaphr.Semaphr
      <br></br>
      <br></br>
      <span className="code-highlighted">Semaphr</span>.configure(this, API_KEY)
      <br></br>
    </div>
  );
};

const PlatformTypeComponent = ({ selectedApp }) => {
  return (
    <div className="row">
      <div className="col-8 two-columns-fields-size">
        <div className="d-flex justify-content-between">
          <PlatformType image={androidImage} title={"Android"} />
          <IntegrationStatus
            integrationStatus={getIntegrationStatusForApp(selectedApp)}
          />
        </div>

        <div className="separator mt-16"> </div>
      </div>
    </div>
  );
};

const RegisterTheAppComponent = ({ identifier, setIdentifier }) => {
  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Register the app"} />
        <div className="integration-description-small">
          Enter the Android package name that you wish to link to this project.
          The package name can be found by following the{" "}
          <a
            href="https://developer.android.com/build/configure-app-module#set_the_application_id"
            target="blank"
          >
            steps here
          </a>
          .
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"Package name"} />
        <div className="integration-description-small">
          <WhiteInputField
            readOnly={false}
            id={"identifier"}
            placeholder={"Enter the Android package name"}
            showEditIcon={true}
            value={identifier}
            onChange={(val) => setIdentifier(val)}
          />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const AddTheAppComponent = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("Gradle");

  var instruction = <GradleCode />;
  var codeToCopy = gradleCodeToCopy();
  if (selectedPlatform === "React") {
    instruction = <ReactCode />;
    codeToCopy = reactCodeToCopy();
  }

  const code = (
    <div className="sdk-code-field">
      <div className="code-field">{instruction}</div>
    </div>
  );

  const copyField = <CopyField value={code} />;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy);
  };

  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Add the SDK"} />
        <div className="integration-description-small">
          Add the plugin to the build.gradle file.<br></br>For additional
          details, see{" "}
          <a href="https://github.com/semaphr/android" target="_blank">
            the developer documentation
          </a>
          .
        </div>
        <div className="library-selector-container">
          <GenericSelector
            extraClassName="library-selector"
            values={["Gradle"]}
            selectedValue={selectedPlatform}
            setSelectedValue={setSelectedPlatform}
          />
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"SDK"} />
        <div className="integration-description-small">{copyField}</div>
        <div className="copy-to-clipboard-container mb-16">
          <SimpleButton text={"Copy to clipboard"} action={copyToClipboard} />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const AddInitialisationCodeComponent = ({
  saveEnabled,
  saveAction,
  deleteAction,
  selectedProject,
}) => {
  const code = (
    <div className="sdk-code-field">
      <div className="code-field">
        <SDKCode />
      </div>
    </div>
  );

  const copyField = (
    <CopyField value={code} extraClassName={"copy-field-large "} />
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sdkCodeToCopy(selectedProject));
  };

  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Add initialisation code"} />
        <div className="integration-description-small">
          Save time and ensuring required components are in place.
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"Code"} />
        <div className="integration-description-small">{copyField}</div>
        <div className="copy-to-clipboard-container mb-16">
          <SimpleButton text={"Copy to clipboard"} action={copyToClipboard} />
        </div>

        <div className="d-flex">
          <DestructiveButton
            title={"Delete"}
            action={deleteAction}
            className="mb-16 mt-16"
            active={true}
            isSlim={true}
          />
          <div className="wh-20"></div>
          <ActionButton
            title={"Update"}
            action={saveAction}
            className="mb-16 mt-16"
            active={saveEnabled}
            isSlim={true}
          />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const EditIntegrationAndroid = ({
  setLoading,
  dismissAction,
  selectedProject,
  selectedApp,
}) => {
  const title = "Edit integration";
  const showFilters = false;
  const [identifier, setIdentifier] = useState(selectedApp.identifier);
  const [deleteApp, setDeleteApp] = useState(false);

  const backAction = () => {
    dismissAction();
  };

  const deleteAction = () => {
    const projectID = selectedProject.id;
    const appID = selectedApp.id;

    setLoading(true);
    deleteApplicationAPICall(
      appID,
      projectID,
      (response) => {
        setLoading(false);
        Notifications.showSuccess("Application deleted");
        dismissAction();
      },
      (err) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const saveAction = () => {
    const projectID = selectedProject.id;
    const appID = selectedApp.id;

    setLoading(true);
    editApplicationAPICall(
      identifier,
      "android",
      appID,
      projectID,
      (response) => {
        setLoading(false);
        Notifications.showSuccess("Application updated");
        dismissAction();
      },
      (err) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const identifierValid =
    isIdentifierValid(identifier) && identifier !== selectedApp.identifier;

  const header = (
    <PlatformHeader
      title={title}
      showFilters={showFilters}
      showBack={true}
      backAction={backAction}
    />
  );

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <PlatformTypeComponent selectedApp={selectedApp} />
      <RegisterTheAppComponent
        identifier={identifier}
        setIdentifier={setIdentifier}
      />
      <AddTheAppComponent />
      <AddInitialisationCodeComponent
        saveEnabled={identifierValid}
        saveAction={saveAction}
        deleteAction={() => setDeleteApp(true)}
        selectedProject={selectedProject}
      />
    </div>
  );

  return (
    <div id="configure-android">
      {header}
      {content}

      <PopupMessage
        title={"Delete app?"}
        subtitle={
          "Are you sure you want to delete your app configuration? By doing this you will lose all of your messages and not be able to retrieve them. "
        }
        actionTitle={"Delete"}
        action={() => {
          deleteAction();
        }}
        open={deleteApp}
        setOpen={(value) => {
          setDeleteApp(false);
        }}
        destructive={true}
      />
    </div>
  );
};

export default EditIntegrationAndroid;
