import React from "react";
import { ActionButton } from "../../generic/ActionButton";
import { ReactComponent as Decoration } from "../../../resources/img/Fold1-button-decoration.svg";
import Asset1 from "../../../resources/img/Fold1-asset1.svg";
import Asset2 from "../../../resources/img/Fold1-asset2.svg";
import Asset3 from "../../../resources/img/Fold1-asset3.svg";
import Asset3s from "../../../resources/img/Fold1-asset3.png";
import Asset2Small from "../../../resources/img/Fold1-asset2-small.svg";
import Asset2sSmall from "../../../resources/img/Fold1-asset2-small.png";
import Asset3Small from "../../../resources/img/Fold1-asset3-small.svg";
import Asset3sSmall from "../../../resources/img/Fold1-asset3s-small.png";
import Fold1Decoration from "../../../resources/img/fold-1-decoration.svg";
import Fold1Decoration2 from "../../../resources/img/fold-1-decoration2.svg";
import Fold1Decoration3 from "../../../resources/img/fold-1-decoration3.svg";
import { isSafari } from "react-device-detect";
import { navigateToRegister } from "../../../helpers/NavigationHelper";

const Fold1 = () => {
  return (
    <div className="fold1-page">
      <div className="container">
        <div className="display-on-big">
          <div className="fold1-decoration">
            <img src={Fold1Decoration} alt="decoration" />
          </div>
        </div>

        <div className="display-on-big">
          <div className="fold1-decoration2">
            <img src={Fold1Decoration2} alt="decoration" />
          </div>
        </div>

        <div className="row d-flex align-items-center ">
          <div className="col d-flex flex-column align-items-center z-index-2">
            <h1 className="fold-1-title mt-60">
              {/* Force update, notify, or block mobile app versions. */}
              All-in-one kill switch for your mobile apps.
            </h1>
            <p className="fold-1-subtitle mt-40">
              With semaphr, you can force users to update to the latest version
              of your mobile apps, encourage app updates, or alert them of any
              issues with the app.
            </p>
            <div className="fold-1-button-wrapper">
              <ActionButton
                title={"Get started"}
                className={"fold-1-button mt-40 mb-20"}
                action={() => navigateToRegister()}
              />
              {/* <div className='display-on-big'>
                            <Decoration className='fold-1-button-decoration' />
                        </div> */}
            </div>
          </div>
        </div>

        <div className="display-on-big ">
          <div className="fold-1-asset-wrapper ">
            <div className="d-flex ">
              <img src={Asset1} alt="asset" className="w-100 z-index-2"></img>
            </div>
            <div className="d-flex ">
              <img src={Asset2} alt="asset" className="w-100 z-index-2"></img>
            </div>

            <div className="d-flex">
              <img
                style={{ maxHeight: "628px", maxWidth: "630px" }}
                src={Asset3s}
                alt="asset"
                className="w-100 last-asset z-index-2"
              ></img>
            </div>
          </div>
        </div>
        <div className="display-on-small">
          <div className="fold1-decoration-small">
            <img src={Fold1Decoration3} alt="decoration" />
          </div>
        </div>
        <div className="display-on-small ">
          <div className="fold-1-asset-wrapper-small">
            <div className="col z-index-2">
              <img src={Asset3sSmall} alt="asset" className="img-fluid"></img>
            </div>

            <div className="col z-index-2">
              <img
                style={{ maxHeight: "559px" }}
                src={Asset2sSmall}
                alt="asset"
                className="img-fluid"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold1;
