import gearImage from "../../../../resources/img/gear.png";
import { ActionButton } from "../../../generic/ActionButton";

const ConfigureApp = ({ app, isWeb, configureAction }) => {
  var title = "Configure your " + app + " app";
  const subtitle =
    "By keeping your users informed and minimizing downtime, semaphr can help improve customer satisfaction and loyalty.";

  var buttonTitle = "Configure app";

  if (isWeb) {
    title = "Configure your website";
    buttonTitle = "Configure website";
  }

  return (
    <div className="configure-app-view">
      <div className="configure-app-container">
        <div className="gear-image-container">
          <img src={gearImage} className="gear-image" alt="gear" />
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <div className="button-container">
          <ActionButton
            title={buttonTitle}
            action={configureAction}
            className="mt-48"
            isSlim={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigureApp;
