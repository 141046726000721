import React, { useState } from "react";
import { PricingButton } from "./PricingButton";
import {
  PRICE_ANNUALLY,
  PRICE_MONTHLY,
} from "../../../constants/SiteConstants";
import { PricingPlanCard } from "./PricingPlanCard";

import PricingCarousel from "./PricingCarousel";
import { navigateToRegister } from "../../../helpers/NavigationHelper";

const PricingPage = () => {
  const [plan, setPlan] = useState(PRICE_MONTHLY);

  const sendEmailHandler = (e) => {
    e.preventDefault();
    window.location.href = "mailto:info@semaphr.com";
  };

  const pricingCards = [
    {
      planType: "Startup",
      buttonTitle: "Start now",
      planPrice: "Free",
      planPeriod: PRICE_MONTHLY,
      secondaryCard: false,
      action: navigateToRegister,
      checkList: [
        "100% free, forever",
        "Unlimited logs",
        "Native and cross platform SDKs",
        "Block, notify and update messages",
        "Usage status",
        "Unlimited team members",
      ],
    },

    {
      planType: "Business",
      buttonTitle: "Contact sales",
      planPrice: "Custom",
      planPeriod: PRICE_MONTHLY,
      secondaryCard: true,
      action: sendEmailHandler,
      checkList: [
        "Everything in startup",
        "Messages customization (branding)",
        "Instant messages display",
        "Analytics export",
        "Integration support",
        "Enhanced encryption and security",
      ],
    },
  ];

  return (
    <div id="pricing" className="pricing-page ">
      <div className="container d-flex justify-content-center flex-column">
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <div className=" pricing-page-title">
            <h1>
              <span>Simple plans</span> for everyone
            </h1>
          </div>
          <div className="pricing-page-subtitle mb-60">
            <p>
              Whether you're a startup, an indie developer, or an established
              business, we got you covered.
            </p>
          </div>
        </div>

        {/* <div className='pricing-page-options-wrapper'>
                <PricingButton
                    text={PRICE_MONTHLY}
                    action={() => setPlan(PRICE_MONTHLY)}
                    active={plan === PRICE_MONTHLY} />
                <PricingButton
                    text={PRICE_ANNUALLY}
                    action={() => setPlan(PRICE_ANNUALLY)}
                    active={plan === PRICE_ANNUALLY} />
            </div> */}
        <div className="pricing-page-sub-plans-wrapper">
          {pricingCards.map((item, index) => (
            <PricingPlanCard
              planType={item.planType}
              planPrice={item.planPrice}
              planPeriod={plan}
              checkList={item.checkList}
              secondaryCard={item.secondaryCard}
              buttonTitle={item.buttonTitle}
              action={item.action}
            />
          ))}
        </div>

        <div className="display-on-small overflow-hidden">
          <div className="d-flex justify-content-center position-relative">
            <PricingCarousel pricingCards={pricingCards} plan={plan} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
