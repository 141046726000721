export const InputField = ({
  placeholder,
  id,
  type,
  error = false,
  onChange = null,
  value = null,
}) => {
  var inputClassName = "input-field-background";
  if (error) {
    inputClassName += " error-field";
  }

  return (
    <div className={inputClassName}>
      <div>
        <input
          className="input-field"
          type={type}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
        ></input>
      </div>
    </div>
  );
};
