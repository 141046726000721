import React from "react";
import HowWorkRow from "./HowWorkRow";
import HowWorkAsset1 from "../../../resources/img/how-work-asset1.svg";
import HowWorkAsset1Small from "../../../resources/img/how-work-asset1-small.svg";
import HowWorkAsset1Icon from "../../../resources/img/how-work-row-icon1.svg";
import HowWorkAsset2 from "../../../resources/img/how-work-asset2.png";

import HowWorkAsset2Small from "../../../resources/img/how-work-asset2-small.svg";
import HowWorkAsset2Icon from "../../../resources/img/how-work-row-icon2.svg";
import HowWorkAsset3 from "../../../resources/img/how-we-work-3.png";
import HowWorkAsset3Small from "../../../resources/img/how-work-asset3-small.png";
import HowWorkAsset3Icon from "../../../resources/img/how-work-row-icon3.svg";

const HowWork = () => {
  return (
    <div className="how-work-page">
      <div className="container p-0">
        <div className="how-work-page-title-wrapper">
          <h1 className="how-work-page-title">How does it work?</h1>
          <p className="how-work-page-subtitle">
            Semaphr offers a flexible messaging system that helps you to deliver
            personalized and prompt messages to your users.
          </p>
        </div>

        <div className="col how-work-page-rows-wrapper">
          <HowWorkRow
            asset={HowWorkAsset3}
            assetSmall={HowWorkAsset3Small}
            icon={HowWorkAsset3Icon}
            title={
              "Messages tailored specifically for each version of your app."
            }
            description={
              "Add a message title, and a description (including links), select a message type,  preview your message, and deliver it to specific app versions at the right time by selecting the delivery schedule."
            }
          />

          <HowWorkRow
            reverse={true}
            asset={HowWorkAsset2}
            assetSmall={HowWorkAsset2Small}
            icon={HowWorkAsset2Icon}
            title={"A message type for every possible use case."}
            description={
              "With 3 message types to choose from, you can block certain versions of the app, encourage users to update their app, or notify them of any issues and restrict the app access until resolved."
            }
          />

          <div className="z-index-2">
            <HowWorkRow
              asset={HowWorkAsset1}
              assetSmall={HowWorkAsset1Small}
              icon={HowWorkAsset1Icon}
              title={"Version insights and analytics"}
              description={
                "You can easily monitor the active users on each version of your app, and track their migration in the dashboard. Additionally, you can see how many users are affected by your latest messages."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWork;
