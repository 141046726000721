import { useEffect, useState } from "react";
import PlatformHeader from "../../../header/PlatformHeader";

import closeImage from "../../../../../resources/img/close.svg";

import TypeComponent, { BLOCK, NOTIFY, UPDATE } from "../../../generic/Type";

import MessageItemTitle from "./components/MessageItemTitle";
import MessageSectionTitle from "./components/MessageSectionTitle";
import MessageField from "./components/MessageField";
import TextareaField from "./components/TextAreaField";
import UploadIconContainer from "./components/UploadIconContainer";
import MessageType from "./components/MessageType";

import MessageDatePicker from "./components/MessageDatePicker";
import { ActionButton } from "../../../../generic/ActionButton";
import PopupMessage from "../../../generic/PopupMessage";
import DropdownWithOptions from "../../../generic/DropdownWithOption";
import $ from "jquery";
import { addMessageAPICall } from "../../../../model/api/MessagesService";
import Notifications from "../../../../../helpers/Notifications";

import mobileFrameImage from "../../../../../resources/img/mobile-frame.png";

import closeIcon from "../../../../../resources/img/close-icon.svg";
import { WEB } from "../../../../../constants/PlatformConstants";
import { versionsForApplicationAPICall } from "../../../../model/api/ApplicationsService";

import {
  iconForType,
  actionTitle,
  RANGES,
} from "./components/MessagesComponentHelper";
import moment from "moment";

const MobileMessagePreview = ({
  image,
  title,
  subtitle,
  dismissable,
  action,
}) => {
  var titleToDisplay = "Message title";
  if (title) {
    titleToDisplay = title;
  }

  var subtitleToDisplay =
    "Add a message, you can even add links https://semaphr.com.";
  if (subtitle) {
    subtitleToDisplay = subtitle;
  }

  var dismissImage;
  if (dismissable) {
    dismissImage = <img src={closeIcon} alt="" />;
  }

  var actionTitle;
  if (action) {
    actionTitle = <div className="button">{action}</div>;
  }

  return (
    <div className="mobile-message-preview-container">
      <div className="background-image">
        <img src={mobileFrameImage} alt="background" />
        <div className="overlay">
          <div className="header">
            <div className="close">{dismissImage}</div>
          </div>
          <div className="editable-values">
            <img className="icon" src={image} alt="icon" />
            <div className="w-100 "><h1 className="title text-overflow">{titleToDisplay}</h1></div>
            <div className="w-100 "><h2 className="description text-overflow">{subtitleToDisplay}</h2></div>
          </div>
          <div className="action">{actionTitle}</div>
        </div>
      </div>
    </div>
  );
};

const WebMessagePreview = ({ image, title, subtitle, dismissable, action }) => {
  var titleToDisplay = "Message title";
  if (title) {
    titleToDisplay = title;
  }

  var subtitleToDisplay =
    "Add a message, you can even add links https://semaphr.com.";
  if (subtitle) {
    subtitleToDisplay = subtitle;
  }

  var dismissImage;
  if (dismissable) {
    dismissImage = <img src={closeIcon} alt="" />;
  }

  var actionTitle;
  if (action) {
    actionTitle = <div className="button">{action}</div>;
  }

  return (
    <div className="mobile-message-preview-container">
      <div className="background-image">
        <img src={mobileFrameImage} alt="background" />

        <div className="overlay">
          <div className="header">
            <div className="close">{dismissImage}</div>
          </div>
          <div className="editable-values">
            <img className="icon d-flex justify-content-center" src={image} alt="icon" />
            <div className="w-100 "><h1 className="title text-overflow">{titleToDisplay}</h1></div>
            <div className="w-100 "><h2 className="description text-overflow">{subtitleToDisplay}</h2></div>
          </div>
          <div className="action">{actionTitle}</div>
        </div>
      </div>
    </div>
  );
};

const MessageDetailsSection = ({ detailsChanged }) => {
  return (
    <div className="row">
      <MessageSectionTitle title={"Message details"} />
      <div className="col-8 settings-messages-column">
        <MessageItemTitle title={"Title"} className={"mt-24"} />
        <MessageField
          placeholderText={"Type the title of the message"}
          className={"mt-8"}
          idValue={"title"}
          onChange={detailsChanged}
        />

        <MessageItemTitle title={"Description"} className={"mt-24"} />
        <TextareaField
          placeholderText={
            "Type the description of the message, you can include links too."
          }
          className={"mt-8"}
          idValue={"description"}
          onChange={detailsChanged}
        />
      </div>
      <div className="col"></div>
      <div className="col-3 settings-icon-column">
        {/* <MessageItemTitle title={"Icon"} className={"mt-24"} />
        <UploadIconContainer /> */}
      </div>
    </div>
  );
};

const MessageVersionSection = ({
  selectedType,
  setSelectedType,
  updateSkipAllowed,
  setUpdateSkipAllowed,
  notifySkipAllowed,
  setNotifySkipAllowed,
  setSelectedVersion,
  selectedVersion,
  selectedRange,
  setSelectedRange,
  versions,
}) => {
  return (
    <div>
      <div className="mt-24">
        <MessageSectionTitle title={"Message type"} />
      </div>
      <div className="row mt-24">
        <div className="col">
          <MessageType
            type={BLOCK}
            selected={selectedType === BLOCK}
            setSelected={() => {
              setSelectedType(BLOCK);
            }}
            app={true}
            skipAllowed={false}
            setSkipAllowed={() => { }}
          />
        </div>

        <div className="col">
          <MessageType
            type={UPDATE}
            selected={selectedType === UPDATE}
            setSelected={() => {
              setSelectedType(UPDATE);
            }}
            app={true}
            skipAllowed={updateSkipAllowed}
            setSkipAllowed={setUpdateSkipAllowed}
          />
        </div>

        <div className="col">
          <MessageType
            type={NOTIFY}
            selected={selectedType === NOTIFY}
            setSelected={() => {
              setSelectedType(NOTIFY);
            }}
            app={true}
            skipAllowed={notifySkipAllowed}
            setSkipAllowed={setNotifySkipAllowed}
          />
        </div>
      </div>

      <MessageItemTitle title={"Versions"} className={"mt-24"} />
      <div className="row mt-24">
        <div className="col-4">
          <div className="range-dropdown">
            <DropdownWithOptions
              placeholder={"Choose range..."}
              values={RANGES}
              selectedValue={selectedRange}
              setSelectedValue={setSelectedRange}
              capitalize={false}
            />
          </div>
        </div>
        <div className="col-4">
          <DropdownWithOptions
            placeholder={"Choose version..."}
            values={versions}
            selectedValue={selectedVersion}
            setSelectedValue={setSelectedVersion}
          />
        </div>
      </div>
    </div>
  );
};

const DatesSection = ({ setStartDate, startDate, setEndDate, endDate }) => {
  return (
    <div className="dates-section mt-24">
      <MessageSectionTitle title={"Schedule message"} />

      <div className="row mt-24">
        <div className="col-4">
          <MessageItemTitle title={"Start Date"} />

          <MessageDatePicker
            pickedDate={startDate}
            setPickedDate={setStartDate}
            className={"mt-24"}
          />
        </div>

        <div className="col-4">
          <MessageItemTitle title={"End Date"} optional={true} />
          <MessageDatePicker
            pickedDate={endDate}
            setPickedDate={setEndDate}
            className={"mt-24"}
          />
        </div>
      </div>
    </div>
  );
};

const ActionsSection = ({ canAddMessage, addMessage }) => {
  const publishAction = (
    <ActionButton
      title={"Publish message"}
      action={addMessage}
      className="mt-48"
      active={canAddMessage}
      isSlim={true}
    />
  );

  return <div className="actions-section mb-24">{publishAction}</div>;
};

const CreateAppContainer = ({
  setLoading,
  cancelAction,
  selectedProject,
  platform,
}) => {
  const header = <div className="header"></div>;

  const [selectedType, setSelectedType] = useState(BLOCK);
  const [publishPopupOpen, setPublishPopupOpen] = useState(false);
  const [updateSkipAllowed, setUpdateSkipAllowed] = useState(false);
  const [notifySkipAllowed, setNotifySkipAllowed] = useState(false);
  const [canAddMessage, setCanAddMessage] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [versions, setVersions] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [canSkip, setCanSkip] = useState(false);

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [icon, setIcon] = useState(null);
  const [actionButtonTitle, setActionButtonTitle] = useState(null);

  const getVersions = () => {
    setLoading(true);
    versionsForApplicationAPICall(
      selectedProject.id,
      platform,
      (result) => {
        setLoading(false);
        setVersions(result.data.versions);
      },
      (err) => {
        setLoading(false);
        Notifications.showError(
          "There are no applications linked to this platform, run the SDK at least once to configure a message."
        );
      }
    );
  };

  const addMessage = () => {
    const title = $("#title").val();
    const description = $("#description").val();
    const type = selectedType;
    var skip = false;

    var range;
    const version = selectedVersion;
    const start = startDate;
    const end = endDate;

    if (type === UPDATE) {
      skip = updateSkipAllowed;
    }

    if (type === NOTIFY) {
      skip = notifySkipAllowed;
    }

    if (selectedRange === "Exact (=)") {
      range = "exact";
    }

    if (selectedRange === "Lower then (<)") {
      range = "lower";
    }

    setLoading(true);
    addMessageAPICall(
      selectedProject.id,
      title,
      description,
      skip,
      platform,
      version,
      range,
      type,
      start,
      end,
      (result) => {
        setLoading(false);
        Notifications.showSuccess("Message added");
        cancelAction();
      },
      (err) => {
        setLoading(false);
        Notifications.showError(
          "We could not add this rule, check you don't have any other duplicated rules."
        );
      }
    );
  };

  const detailsChanged = () => {
    const title = $("#title").val();
    const description = $("#description").val();
    const type = selectedType;
    var skip = false;

    var range;
    const version = selectedVersion;
    const start = startDate;
    const end = endDate;
    let endDateValid = false;

    if (type === UPDATE) {
      skip = updateSkipAllowed;
    }

    if (type === NOTIFY) {
      skip = notifySkipAllowed;
    }

    if (selectedRange === "Exact (=)") {
      range = "exact";
    }

    if (selectedRange === "Lower then (<)") {
      range = "lower";
    }

    setTitle(title);
    setDescription(description);
    setCanSkip(skip);
    setIcon(iconForType(type));
    setActionButtonTitle(actionTitle(type, skip));

    const titleValid = title.length > 3;
    const descriptionValid = description.length > 0;
    const versionValid = version != null;
    const rangeValid = range != null;
    const startDateValid = startDate != null;

    if (startDate) {
      if (endDate) {
        endDateValid = endDate.getTime() > startDate.getTime();
      } else {
        endDateValid = true;
      }

    }


    const canMakeAPICall =
      titleValid &&
      descriptionValid &&
      versionValid &&
      rangeValid &&
      startDateValid &&
      endDateValid;

    if (!endDateValid && endDate) {
      Notifications.showError("Can't set End Date before start date")
    }

    setCanAddMessage(canMakeAPICall);

    console.log("selectedRange", selectedRange);

    console.log(
      "title:",
      title,
      " desc:",
      description,
      " type: ",
      type,
      "skip",
      skip,
      "range",
      range,
      "version",
      version,
      "start",
      start,
      "end",
      end,
      "endDateValid", endDateValid
    );
  };

  useEffect(() => {
    detailsChanged();
  }, [
    selectedType,
    updateSkipAllowed,
    notifySkipAllowed,
    selectedVersion,
    selectedRange,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    getVersions();
  }, []);

  const leftColumn = (
    <div className="settings container">
      <MessageDetailsSection detailsChanged={detailsChanged} />
      <MessageVersionSection
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        updateSkipAllowed={updateSkipAllowed}
        setUpdateSkipAllowed={setUpdateSkipAllowed}
        notifySkipAllowed={notifySkipAllowed}
        setNotifySkipAllowed={setNotifySkipAllowed}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        versions={versions}
      />
      <DatesSection
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
      />
      <ActionsSection
        canAddMessage={canAddMessage}
        addMessage={() => {
          setPublishPopupOpen(true);
        }}
      />
    </div>
  );

  var mobilePreview = (
    <MobileMessagePreview
      image={icon}
      title={title}
      subtitle={description}
      dismissable={canSkip}
      action={actionButtonTitle}
    />
  );

  const webPreview = (
    <WebMessagePreview
      image={icon}
      title={title}
      subtitle={description}
      dismissable={canSkip}
      action={actionButtonTitle}
    />
  );

  var preview = mobilePreview;
  // if (platform === WEB) {
  preview = webPreview;
  // }

  const rightColumn = (
    <div className="preview">
      <MessageSectionTitle title={"Message preview"} />
      {preview}
    </div>
  );

  const content = (
    <div className="message-container-wrapper platform-margins mt-16">
      <div className="message-container">
        {header}
        <div className="container">
          <div className="row">
            <div className="col-7 message-settings-col">{leftColumn}</div>
            <div className="col-1"></div>
            <div className="col-4 message-prev-col">{rightColumn}</div>
          </div>
        </div>
      </div>

      <PopupMessage
        title={"Warning!"}
        subtitle={
          "By clicking the 'publish' button, your message will be sent to all app users on the selected date. Don't worry, you can still edit the message later if needed. Are you sure you want to proceed?"
        }
        actionTitle={"Publish"}
        action={() => {
          addMessage();
        }}
        open={publishPopupOpen}
        setOpen={setPublishPopupOpen}
      />
    </div>
  );

  return content;
};

const CreateAppMessage = ({
  setLoading,
  cancelAction,
  selectedProject,
  platform,
}) => {
  const title = "Create message";
  const showFilters = false;

  const header = (
    <PlatformHeader
      title={title}
      showFilters={showFilters}
      showBack={true}
      backAction={cancelAction}
    />
  );

  const content = (
    <CreateAppContainer
      setLoading={setLoading}
      cancelAction={cancelAction}
      selectedProject={selectedProject}
      platform={platform}
    />
  );

  return (
    <div id="create-app-messages">
      {header}
      {content}
    </div>
  );
};

export default CreateAppMessage;
