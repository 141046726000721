import React from "react";
import SiteHeader from "../../components/site/header/SiteHeader";
import SiteFooter from "../../components/site/footer/SiteFooter";
import FaqPage from "../../components/site/faq/FaqPage";
import PricingPage from "../../components/site/pricing/PricingPage";
import ForTeams from "../../components/site/forTeams/ForTeams";
import ReachYourUsers from "../../components/site/ReachYourUsers/ReachYourUsers";
import Fold3 from "../../components/site/Fold3/Fold3";
import Fold1 from "../../components/site/Fold1/Fold1";
import HowWork from "../../components/site/How semaphr work/HowWork";
import UsedBy from "../../components/site/usedBy/UsedBy";
import LovedByDev from "../../components/site/lovefByDev/LovedByDev";
import SeeInActionPage from "../../components/site/SeeInAction/SeeInActionPage";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const Sitepage = () => {
  return (
    <div id="site" className="full-screen-item position-relative">
      <SiteHeader />
      <Fold1 />
      {/* <UsedBy /> */}
      <HowWork />
      <SeeInActionPage />
      <Fold3 />
      <ReachYourUsers />
      <ForTeams />
      <PricingPage />
      {/* <LovedByDev /> */}
      <FaqPage />
      <SiteFooter />
      <TrackingComponent />
    </div>
  );
};

export default Sitepage;
