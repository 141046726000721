import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/authentication/LoginPage";
import ResetPasswordPage from "./pages/authentication/ResetPasswordPage";
import NewPasswordPage from "./pages/authentication/NewPasswordPage";
import CreateNewProjectPage from "./pages/authentication/CreateNewProjectPage";
import RegisterPage from "./pages/authentication/Register";
import CreateAccountFromInvitePage from "./pages/authentication/CreateAccountFromInvitePage";

import PlatformPage from "./pages/platform/PlatformPage";

import { SpinnerComponent } from "./components/generic/SpinnerComponent";
import { useState } from "react";
import Sitepage from "./pages/site/Sitepage";
import UserContextProvider from "./components/model/context/UserContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserContext } from "./components/model/context/UserContext";
import { navigateToLogin } from "./helpers/NavigationHelper";
import CookieConsent from "./components/cookies/CookieConsent";
import { blogPostsArray } from "./components/blog/BlogPostBuilder";
import BlogPostPage from "./pages/site/BlogPostsPage";
import BlogPage from "./pages/site/BlogPage";
import PrivacyPolicyPage from "./pages/site/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/site/TermsAndConditionsPage";

function App() {
  const [loading, setLoading] = useState(false);

  const generateBlogPosts = (setLoading) => {
    let routes = [];

    blogPostsArray().forEach((element) => {
      routes.push(
        <Route
          path={element().href}
          element={
            <BlogPostPage setLoading={setLoading} blogPost={element()} />
          }
        />
      );
    });

    return routes;
  };

  const components = (
    <UserContextProvider setLoading={setLoading}>
      <div className="App">
        <ToastContainer position="bottom-right" />
        <Routes>
          <Route path="/" element={<Sitepage setLoading={setLoading} />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route
            path="/dashboard"
            element={<PlatformPage setLoading={setLoading} />}
          />

          <Route
            path="/login"
            element={<LoginPage setLoading={setLoading} />}
          />

          <Route
            path="/accept-invite"
            element={<CreateAccountFromInvitePage setLoading={setLoading} />}
          />

          <Route
            path="/register"
            element={<RegisterPage setLoading={setLoading} />}
          />

          <Route
            path="/reset-password"
            element={<ResetPasswordPage setLoading={setLoading} />}
          />

          <Route
            path="/new-password"
            element={<NewPasswordPage setLoading={setLoading} />}
          />
          {/* Blog posts routes */}
          {generateBlogPosts(setLoading)}
        </Routes>
      </div>
    </UserContextProvider>
  );

  return (
    <BrowserRouter>
      <SpinnerComponent
        loading={loading}
        components={components}
      ></SpinnerComponent>
      <CookieConsent />
    </BrowserRouter>
  );
}

export default App;
