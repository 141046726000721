import logoImage from "../../../resources/img/logo.svg";

import DropDown from "./DropDown";
import MenuItems from "./MenuItems";
import { MenuItem } from "./MenuItems";
import { MENU_PROFILE } from "../../../constants/SideBarConstants";

import profileIcon from "../../../resources/img/profile.svg";
import profileIconSelected from "../../../resources/img/profile-selected.svg";
import { MENU_DASHBOARD } from "../../../constants/SideBarConstants";

const SideBar = ({
  setLoading,
  projects,
  selectedProjectID,
  setSelectedProjectID,
  selectedMenu,
  setSelectedMenu,
  addNewProject,
  currentUser,
}) => {
  var user = currentUser();
  var name = "Profile";
  if (user && user.name) {
    name = user.name;
  }

  const topContent = (
    <div className="sidebar-content-top">
      <div
        className="sidebar-logo-container"
        onClick={() => {
          setSelectedMenu(MENU_DASHBOARD);
        }}
      >
        <img src={logoImage} className="sidebar-logo" alt="logo" />
      </div>
      <div className="dropdown-container mt-32">
        <DropDown
          selectedValueID={selectedProjectID}
          setSelectedValueID={setSelectedProjectID}
          values={projects}
          addNewProject={addNewProject}
        />
      </div>
    </div>
  );

  const menu = (
    <div className="options mt-48">
      <MenuItems
        selectedValue={selectedMenu}
        setSelectedValue={setSelectedMenu}
      />
    </div>
  );

  const profile = (
    <div className="profile">
      <MenuItem
        title={name}
        image={profileIcon}
        selectedImage={profileIconSelected}
        selected={selectedMenu === MENU_PROFILE}
        setSelected={setSelectedMenu}
        selectionKey={MENU_PROFILE}
      />
    </div>
  );

  return <div className="sidebar">{[topContent, menu, profile]}</div>;
};

export default SideBar;
