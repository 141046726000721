import { useState } from "react";
import PlatformHeader from "../../header/PlatformHeader";
import SectionTitle from "../../generic/DashboardSectionTitle";
import WhiteInputField from "../../generic/WhiteInputField";
import DropdownWithOptions from "../../generic/DropdownWithOption";
import { SmallActionButton } from "../../../generic/SmallActionButton";

import { SimpleButton } from "../../../generic/SimpleButton";

import { imageForPlatform } from "../../generic/PlatformHelper";

import IntegrationStatus from "./elements/IntegrationStatus";
import EditButton from "../../generic/EditButton";
import ConfigureButton from "../../generic/ConfigureButton";
import {
  INTEGRATION_NOT_CONNECTED,
  INTEGRATION_PENDING,
  INTEGRATION_CONNECTED,
  getIntegrationStatusForApp,
  getIdentifierForApp,
} from "../../generic/PlatformHelper";

import ConfigureIntegrationIos from "./configure/ConfigureIntegrationIos";
import ConfigureIntegrationAndroid from "./configure/ConfigureIntegrationAndroid";
import ConfigureIntegrationWeb from "./configure/ConfigureIntegrationWeb";

import {
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  PLATFORM_WEB,
} from "../../generic/PlatformHelper";
import { WEB } from "../../../../constants/PlatformConstants";
import EditIntegrationIos from "./configure/EditIntegrationIos";
import EditIntegrationAndroid from "./configure/EditIntegrationAndroid";
import EditIntegrationWeb from "./configure/EditIntegrationWeb";

const LinkedApplication = ({
  platform,
  integrationStatus,
  identifier,
  action,
}) => {
  const image = imageForPlatform(platform);

  var button = <EditButton action={action} />;
  if (integrationStatus === INTEGRATION_NOT_CONNECTED) {
    button = <ConfigureButton action={action} />;
  }

  return (
    <div className="linked-application">
      <div className="platform-container">
        <div className="image">
          <img src={image} alt={"platform"} />
        </div>
        <div className="platform">{platform}</div>
      </div>
      <div className="status">
        <IntegrationStatus integrationStatus={integrationStatus} />
      </div>
      <div className="identifier">{identifier}</div>
      <div className="action">{button}</div>
    </div>
  );
};

const LinkedApplications = ({
  selectedProject,
  setIntegrationContainer,
  setLoading,
  reloadProject,
}) => {
  const iOS = selectedProject.ios_application;
  const android = selectedProject.android_application;
  const web = selectedProject.web_application;

  const dismissContainer = () => {
    reloadProject();
    setIntegrationContainer(null);
  };

  const configure = (type) => {
    var integrationContainer;

    if (type === PLATFORM_IOS) {
      integrationContainer = (
        <ConfigureIntegrationIos
          setLoading={setLoading}
          dismissAction={dismissContainer}
          selectedProject={selectedProject}
        />
      );
    }

    if (type === PLATFORM_ANDROID) {
      integrationContainer = (
        <ConfigureIntegrationAndroid
          setLoading={setLoading}
          dismissAction={dismissContainer}
          selectedProject={selectedProject}
        />
      );
    }

    if (type === PLATFORM_WEB) {
      integrationContainer = (
        <ConfigureIntegrationWeb
          setLoading={setLoading}
          dismissAction={dismissContainer}
          selectedProject={selectedProject}
        />
      );
    }

    if (integrationContainer) {
      setIntegrationContainer(integrationContainer);
    }
  };

  const edit = (app) => {
    var integrationContainer;

    if (app.platform === "ios") {
      integrationContainer = (
        <EditIntegrationIos
          setLoading={setLoading}
          dismissAction={dismissContainer}
          selectedProject={selectedProject}
          selectedApp={app}
        />
      );
    }

    if (app.platform === "android") {
      integrationContainer = (
        <EditIntegrationAndroid
          setLoading={setLoading}
          dismissAction={dismissContainer}
          selectedProject={selectedProject}
          selectedApp={app}
        />
      );
    }

    if (app.platform === "web") {
      integrationContainer = (
        <EditIntegrationWeb
          setLoading={setLoading}
          dismissAction={dismissContainer}
          selectedProject={selectedProject}
          selectedApp={app}
        />
      );
    }

    if (integrationContainer) {
      setIntegrationContainer(integrationContainer);
    }
  };

  const action = (app, type) => {
    if (!app) {
      configure(type);
    } else {
      edit(app);
    }
  };

  return (
    <div className="container no-margins-container linked-applications-container">
      {/* <LinkedApplication
        platform={PLATFORM_WEB}
        integrationStatus={getIntegrationStatusForApp(web)}
        identifier={getIdentifierForApp(web)}
        action={() => {
          action(web, PLATFORM_WEB);
        }}
      /> */}
      <LinkedApplication
        platform={PLATFORM_IOS}
        integrationStatus={getIntegrationStatusForApp(iOS)}
        identifier={getIdentifierForApp(iOS)}
        action={() => {
          action(iOS, PLATFORM_IOS);
        }}
      />
      <LinkedApplication
        platform={PLATFORM_ANDROID}
        integrationStatus={getIntegrationStatusForApp(android)}
        identifier={getIdentifierForApp(android)}
        action={() => {
          action(android, PLATFORM_ANDROID);
        }}
      />
    </div>
  );
};

const Integrations = ({ setLoading, selectedProject, reloadProject }) => {
  const title = "Integrations";
  const showFilters = false;

  const [integrationContainer, setIntegrationContainer] = useState(null);

  const header = <PlatformHeader title={title} showFilters={showFilters} />;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedProject.api_key);
  };

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <div className="row">
        <SectionTitle title={"API Key"} />

        <div className="col one-column-field-size">
          <div className="integration-description">
            The Key is used for linking the libraries to your account. You can
            not change this value.
          </div>
          <WhiteInputField
            readOnly={true}
            value={"••••••••••••••••••••••••••••••••"}
            showEditIcon={false}
          />
          <div className="copy-to-clipboard-container">
            <SimpleButton text={"Copy to clipboard"} action={copyToClipboard} />
          </div>
        </div>
      </div>

      <div className="row mt-24">
        <div className="col-8 two-columns-fields-size">
          <div className="separator mt-24 mb-24"></div>
        </div>
      </div>

      <div className="row mt-24">
        <SectionTitle title={"Linked applications"} />
        <div className="integration-description">
          See developer{" "}
          <a href="https://github.com/semaphr/" target="_blank">
            documentation
          </a>
          .
        </div>
        <div className="col-8 two-columns-fields-size">
          <LinkedApplications
            selectedProject={selectedProject}
            setIntegrationContainer={setIntegrationContainer}
            setLoading={setLoading}
            reloadProject={reloadProject}
          />
        </div>
      </div>
    </div>
  );

  var contentToDisplay = [header, content];
  if (integrationContainer) {
    contentToDisplay = integrationContainer;
  }

  return <div id="integrations">{contentToDisplay}</div>;
};

export default Integrations;
