export const navigateToLogin = () => {
  window.location.href = "/login";
};

export const navigateToMainPage = () => {
  window.location.href = "/";
};

export const navigateToRegister = () => {
  window.location.href = "/register";
};

export const navigateToHomeWithPath = (path) => {
  window.location.href = "/" + path;
};
export const navigateToAnotherSite = (path) => {
  window.open(path, "_blank");
};

export const navigateToBlog = () => {
  window.location.href = "/blog";
};

export const navigateToPrivacy = () => {
  window.location.href = "/privacy-policy";
};

export const navigateToTerms = () => {
  window.location.href = "/terms-and-conditions";
};

export const navigateToDashboard = () => {
  window.location.href = "/dashboard";
};

export const navigateToForgotPassword = () => {
  window.location.href = "/reset-password";
};
