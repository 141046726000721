import $ from "jquery";

import { useEffect, useState } from "react";
import PlatformHeader from "../../header/PlatformHeader";
import SectionTitle from "../../generic/DashboardSectionTitle";
import WhiteInputField from "../../generic/WhiteInputField";
import DropdownWithOptions from "../../generic/DropdownWithOption";
import { SmallActionButton } from "../../../generic/SmallActionButton";
import {
  editProjectAPICall,
  getMembersAPICall,
  inviteMemberAPICall,
  removeMemberAPICall,
} from "../../../model/api/ProjectsService";
import Notifications from "../../../../helpers/Notifications";
import { isEmailValid } from "../../../../helpers/ValidatorHelper";
import removeIcon from "../../../../resources/img/remove-icon.svg";
import PopupMessage from "../../generic/PopupMessage";
import { useUserContext } from "../../../model/context/UserContext";

const ADMIN_ROLE = "ADMIN";
const MEMBER_ROLE = "MEMBER";
const ROLES = [ADMIN_ROLE, MEMBER_ROLE];

const MemberRole = ({ role }) => {
  return (
    <div className="member-role">
      <div className="text">{role}</div>
    </div>
  );
};

const MemberRow = ({ name, email, role, member, removeAction, showRemove }) => {
  var removeElem;
  if (showRemove) {
    removeElem = (
      <td className="table-member-action">
        <button
          className="transparent-button"
          onClick={() => removeAction(member)}
        >
          <img src={removeIcon} alt="remove" />
        </button>
      </td>
    );
  }

  return (
    <tr className="member-row">
      <td className="table-name">{name}</td>
      <td className="table-email">{email}</td>
      <td className="table-role">
        <div className="member-role-container">
          <MemberRole role={role} />
        </div>
      </td>
      {removeElem}
    </tr>
  );
};

const MembersTable = ({ members, removeAction, showRemove }) => {
  var membersList = [];

  members.forEach((element) => {
    var name = element.user.name;
    const email = element.user.email;
    const role = element.role === "admin" ? ADMIN_ROLE : MEMBER_ROLE;

    if (!name || name.length === 0) {
      name = "Invited";
    }

    element = (
      <MemberRow
        name={name}
        email={email}
        role={role}
        member={element}
        removeAction={removeAction}
        showRemove={showRemove}
      />
    );

    membersList.push(element);
  });

  var removeElem;
  if (showRemove) {
    removeElem = <th className="table-member-action">REMOVE</th>;
  }

  return (
    <div className="table-container">
      <table className="platform-table">
        <tr>
          <th className="table-name">NAME</th>
          <th className="table-email">EMAIL</th>
          <th className="table-role">ROLE</th>
          {removeElem}
        </tr>
        {membersList}
      </table>
    </div>
  );
};

const Members = ({ setLoading, selectedProject, refreshProjects }) => {
  const title = "Members";
  const showFilters = false;

  const header = <PlatformHeader title={title} showFilters={showFilters} />;
  const { currentUser } = useUserContext();

  const [selectedRole, setSelectedRole] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState(
    selectedProject.name
  );
  const [addMemberButtonEnabled, setAddMemberButtonEnabled] = useState(false);

  const [members, setMembers] = useState([]);

  const [removeMember, setRemoveMember] = useState(null);

  const enableAddButtonIfNeeded = () => {
    const email = $("#email").val();

    if (isEmailValid(email) && selectedProject && selectedRole) {
      setAddMemberButtonEnabled(true);
    } else {
      setAddMemberButtonEnabled(false);
    }
  };

  const isAdmin = selectedProject.is_current_user_admin;

  const fetchMembers = () => {
    setLoading(true);
    getMembersAPICall(
      selectedProject.id,
      (result) => {
        setLoading(false);
        setMembers(result.data.members);
      },
      (error) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const addMemberAction = () => {
    const role = selectedRole.toLowerCase();
    const email = $("#email").val();

    setLoading(true);
    inviteMemberAPICall(
      selectedProject.id,
      email,
      role,
      (response) => {
        setLoading(false);

        $("#email").val(null);
        setSelectedRole(null);

        fetchMembers();

        Notifications.showSuccess("User invited succesfully!");
      },
      () => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const updateProjectName = (name) => {
    setLoading(true);
    const id = selectedProject.id;
    editProjectAPICall(
      id,
      name,
      (response) => {
        setLoading(false);
        refreshProjects();
      },
      (error) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const projectNameChanged = (name) => {
    if (name.length > 0) {
      updateProjectName(name);
    }

    setCurrentProjectName(name);
  };

  const removeMemberAction = (member) => {
    setRemoveMember(member);
  };

  const removeMemberBackendCall = (member) => {
    setLoading(true);
    removeMemberAPICall(
      selectedProject.id,
      member.user.email,
      (response) => {
        setLoading(false);

        $("#email").val(null);
        setSelectedRole(null);

        fetchMembers();

        Notifications.showSuccess("User removed succesfully!");
      },
      () => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    enableAddButtonIfNeeded();
  }, [selectedRole]);

  useEffect(() => {
    setCurrentProjectName(selectedProject.name);
    fetchMembers();
  }, [selectedProject]);

  var addMembersSection;
  if (isAdmin) {
    addMembersSection = (
      <div className="row mt-24">
        <div className="col-4 one-column-field-size">
          <SectionTitle title={"Add member"} />
          <WhiteInputField
            placeholder={"@email.com"}
            showEditIcon={false}
            id={"email"}
            onChange={(val) => {
              enableAddButtonIfNeeded();
            }}
          />
        </div>

        <div className="col-4 one-column-field-size">
          <SectionTitle title={"Member role"} />
          <div className="member-middle-col">
            <DropdownWithOptions
              placeholder={"Choose..."}
              values={ROLES}
              selectedValue={selectedRole}
              setSelectedValue={setSelectedRole}
              containerClass={"member-role-dropdown"}
            />
            <div className="button">
              <SmallActionButton
                title={"Add"}
                action={addMemberAction}
                className="ml-32"
                active={addMemberButtonEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <div className="row">
        <SectionTitle title={"Project name"} />
        <div className="col one-column-field-size">
          <WhiteInputField
            placeholder={"Project name"}
            showEditIcon={true}
            onChange={projectNameChanged}
            id={"projectName"}
            value={currentProjectName}
            readOnly={!isAdmin}
          />
        </div>
      </div>

      {addMembersSection}

      <div className="row mt-24">
        <div className="col-8 two-columns-fields-size">
          <div className="separator mt-24 mb-24"></div>
        </div>
      </div>
      <div className="row mt-24">
        <SectionTitle title={"Project members"} />
        <div className="col-8 two-columns-fields-size">
          <MembersTable
            members={members}
            removeAction={removeMemberAction}
            showRemove={isAdmin}
          />
        </div>
      </div>
    </div>
  );

  const memberName = removeMember ? removeMember.user.name : "member";
  const removeText =
    "By pressing delete, " +
    memberName +
    " will loose access to the current project, do you want to continue?";

  return (
    <div id="members">
      {header}
      {content}
      <PopupMessage
        title={"Delete member?"}
        subtitle={removeText}
        actionTitle={"Delete"}
        action={() => {
          if (removeMember) {
            removeMemberBackendCall(removeMember);
          }
        }}
        open={removeMember != null}
        setOpen={(value) => {
          setRemoveMember(null);
        }}
        destructive={true}
      />
    </div>
  );
};

export default Members;
