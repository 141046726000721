import SelectorButton from "./SelectorButton";

export const SELECTOR_ALL = "all";
export const SELECTOR_ARCHIVED = "archived";

const AllArchiveSelector = ({ selectedKey, setSelectedKey }) => {
  console.log(selectedKey);
  return (
    <div className="all-archive-selector">
      <SelectorButton
        isSelected={selectedKey === SELECTOR_ALL}
        setSelected={() => {
          setSelectedKey(SELECTOR_ALL);
        }}
        title={"All"}
      />
      <SelectorButton
        isSelected={selectedKey === SELECTOR_ARCHIVED}
        setSelected={() => {
          setSelectedKey(SELECTOR_ARCHIVED);
        }}
        title={"Archived"}
      />
    </div>
  );
};

export default AllArchiveSelector;
