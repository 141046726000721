const SelectorButton = ({ isSelected, setSelected, title }) => {
  var className = "selector-button";
  if (isSelected) {
    className += " selector-button-selected";
  }

  return (
    <div
      className={className}
      onClick={() => {
        setSelected();
      }}
    >
      {title}
    </div>
  );
};

export default SelectorButton;
