export const isEmailValid = (email) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(email)) {
    return true;
  }

  return false;
};

export const isNameValid = (name) => {
  if (name.length > 2) {
    return true;
  }

  return false;
};

export const isPasswordValid = (password) => {
  if (password.length > 5) {
    return true;
  }

  return false;
};

export const isIdentifierValid = (identifier) => {
  if (!identifier) {
    return false;
  }

  if (identifier.length < 2) {
    return false;
  }

  return !hasWhiteSpace(identifier);
};

function hasWhiteSpace(s) {
  const whitespaceChars = [" ", "\t", "\n"];
  return whitespaceChars.some((char) => s.includes(char));
}
