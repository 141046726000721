import $ from "jquery";

import { InputField } from "../../components/generic/InputField";
import { SimpleButton } from "../../components/generic/SimpleButton";
import { ActionButton } from "../../components/generic/ActionButton";
import { ErrorLabel } from "../../components/generic/ErrorLabel";
import { ContainerWithLogo } from "../../components/generic/ContainerWithLogo";
import { navigateToRegister } from "../../helpers/NavigationHelper";
import { useState, useEffect, useRef } from "react";
import { isEmailValid } from "../../helpers/ValidatorHelper";
import { useUserContext } from "../../components/model/context/UserContext";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const resetPasswordDefaultComponents = (
  showError,
  setShowError,
  enableSendLink,
  setEnableSetLink,
  resetPassword
) => {
  const onFieldsChange = () => {
    const email = $("#email").val();

    if (email.length > 0 && isEmailValid(email)) {
      setEnableSetLink(true);
      setShowError(false);
    } else {
      setEnableSetLink(false);
      setShowError(true);
    }
  };

  var errorContent;
  if (showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"The email address is not valid"} />
      </div>
    );
  }

  const title = <div className="title text-left mt-24">Reset Password</div>;
  const subtitle = (
    <div className="subtitle text-left mt-8">
      Enter the email address associated with your account and we’ll send you a
      link to reset your password.
    </div>
  );

  const separator = <div className="mt-60"></div>;

  const emailAddress = (
    <InputField
      placeholder={"Enter your email address"}
      id={"email"}
      type={"email"}
      onChange={onFieldsChange}
      value={null}
    />
  );

  const submit = (
    <ActionButton
      title={"Send link"}
      action={() => {
        resetPassword();
      }}
      className="mt-60"
      active={enableSendLink}
    />
  );

  const disclaimer = (
    <div className="authentication-disclaimer">
      Don't have an account yet?{" "}
      <SimpleButton
        text={"Register"}
        action={() => {
          navigateToRegister();
        }}
      />
    </div>
  );

  const components = [
    title,
    subtitle,
    separator,
    emailAddress,
    errorContent,
    submit,
    disclaimer,
  ];

  return components;
};

const resetPasswordPasswordChangedComponents = (
  setShowPasswordSent,
  resendPassword
) => {
  const title = <div className="title text-left mt-24">Check your email</div>;
  const subtitle = (
    <div className="subtitle text-left mt-8">
      We have sent a password recover instructions to your email.
    </div>
  );

  const separator = <div className="mt-60"></div>;

  const lineSeparator = <div className="line-separator mt-48"></div>;
  const didNotReceive = (
    <div className="subsubtitle text-left mt-16">
      Did not receive the email?
    </div>
  );

  const tryAnotherEmail = (
    <SimpleButton
      text={"Try another email address"}
      action={() => {
        setShowPasswordSent(false);
      }}
      extraClass="mt-8"
    />
  );

  const submit = (
    <ActionButton
      title={"Send again"}
      action={() => {
        resendPassword();
      }}
      className="mt-60"
    />
  );

  const disclaimer = (
    <div className="authentication-disclaimer">
      Don't have an account yet?{" "}
      <SimpleButton
        text={"Register"}
        action={() => {
          navigateToRegister();
        }}
      />
    </div>
  );

  const components = [
    title,
    subtitle,
    lineSeparator,
    didNotReceive,
    tryAnotherEmail,
    separator,
    submit,
    disclaimer,
  ];

  return components;
};

const ResetPasswordPage = ({ setLoading }) => {
  const [showPasswordSent, setShowPasswordSent] = useState(false);
  const [showError, setShowError] = useState(false);
  const [enableSendLink, setEnableSetLink] = useState(false);
  const currentEmailAddress = useRef(null);

  const { resetPassword, navigateToDashboardIfPossible } = useUserContext();

  useEffect(() => {
    navigateToDashboardIfPossible();
  }, []);

  const resetPasswordCall = (email) => {
    setLoading(true);

    currentEmailAddress.current = email;

    resetPassword(
      email,
      (response) => {
        console.log("response");
        setLoading(false);
        setShowPasswordSent(true);
      },
      () => {
        console.log("not response");
        setLoading(false);
        setShowError(true);
      }
    );
  };

  const resentPasswordAction = () => {
    if (currentEmailAddress.current) {
      resetPasswordCall(currentEmailAddress.current);
    }
  };

  const resetPasswordAction = () => {
    let email = $("#email").val();

    resetPasswordCall(email);
  };

  var components = resetPasswordDefaultComponents(
    showError,
    setShowError,
    enableSendLink,
    setEnableSetLink,
    resetPasswordAction
  );

  if (showPasswordSent) {
    components = resetPasswordPasswordChangedComponents(
      setShowPasswordSent,
      resentPasswordAction
    );
  }

  return (
    <div id="reset-password" className="full-screen-item">
      <ContainerWithLogo components={components} />
      <TrackingComponent />
    </div>
  );
};

export default ResetPasswordPage;
