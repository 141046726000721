import blogPostImage from "../../../resources/img/blog/block-android.png";

import newAppImage from "../../../resources/img/blog/new-app.png";
import code from "../../../resources/img/blog/android-code.png";
import blockImage from "../../../resources/img/blog/block-image.png";
import configure from "../../../resources/img/blog/configure-android.png";

const HowToBlockUsersFromAndroidApp = () => {
  const href =
    "/blog/how-to-block-user-access-from-your-android-mobile-application";
  const title = "How to block users from using a version of your Android app?";
  const readTime = "4";
  const shortDescription = `With Semaphr, you can block users from accessing certain versions of your Android mobile app.`;

  const content = (
    <div>
      <p>
        There is no way of blocking certain versions of your app once they have
        been released. Google does not provide any mechanisms that allow
        publishers to notify the users who have their apps installed.
      </p>
      <p>
        Since this functionality doesn't come out of the box, developers have to
        implement this on their own. That means creating a separate server,
        integrating the SDK, and building a user interface that allows the app
        version management.
      </p>
      <p>
        Luckily, there's a simple solution, with <b>Semaphr</b>, and this can be
        achieved easily, in 3 easy steps. If you have already integrated
        Semaphr, jump to <a href="#three">step 3</a>.
      </p>

      <h2>1. Register for semaphr</h2>
      <div className="pt-60 pb-60">
        <img src={newAppImage} className="blog-image" alt="new app" />
      </div>
      <p>
        The first step is{" "}
        <a href="https://semaphr.com/register" target="blank">
          creating an account
        </a>{" "}
        and adding a new project.
      </p>
      <p>
        Semaphr, allows you to bundle all your apps in a single account, so if
        you have multiple apps, you can add them all.
      </p>
      <p>
        A project has an iOS and Android application, so if you support both
        platforms with your digital product, you can add them both.
      </p>

      <h2>2. Integrate the SDK</h2>
      <div className="pt-60 pb-60">
        <img src={configure} className="blog-image" alt="new app" />
      </div>
      <p>
        Integrate the SDK, go to <b>Settings -> Integrations</b>, fill in the
        details of your app, and follow the instructions.
      </p>
      <h3>Integrate using Gradle</h3>
      <p>
        If you're using Gradle, copy the Gradle dependency and add it to your
        Gradle file, more details on how to integrate a package can be found{" "}
        <a
          href="https://developer.android.com/build/dependencies"
          target="blank"
        >
          here
        </a>
        .
      </p>

      <h3>Initialization code</h3>
      <div className="pt-60 pb-60">
        <img src={code} className="blog-image" alt="new app" />
      </div>
      <p>
        Add the initialization code, and copy the initialization code (
        <b>
          it will automatically be copied with your API key if you press the
          "Copy to Clipboard" button
        </b>
        ), and add it to your app.
      </p>
      <p>
        Usually, this is added to the AppDelegate, or if you prefer add it to
        your Scene delegate.
      </p>
      <p>Save your integration and run the app.</p>
      <h2 id="three">3. Block an app version</h2>
      <div className="pt-60 pb-60">
        <img src={blockImage} className="blog-image" alt="new app" />
      </div>
      <p>
        To block a certain version of your mobile app you need to go to{" "}
        <b>messages</b> and add a new one with the message type <b>"BLOCK"</b>.
      </p>
      <p>
        Press <b>"Add a new message"</b>, and fill in all the details. Next to
        the form you can see a preview of the message as it will be displayed on
        your users' phones.
      </p>
      <h3>Title & description</h3>
      <p>You need to fill in a message title and a message description.</p>
      <p>
        In the details sections, <b>you can add links</b>, that are going to be
        clickable and will take users to their default web browsers to the link
        you've specified.
      </p>
      <h3>Message type</h3>
      <p>
        As a message type, select <b>Block</b>, this will ensure your users
        won't be able to access the app, in the specified schedule.
      </p>
      <h3>Versions</h3>
      <p>Here you need to select a range (= or &lt;) and an app version.</p>
      <p>
        If you select Exact (=) that means only that specific version will be
        affected by the message. If you select (&lt; Lower than), all the
        versions lower than the selected one will be affected.
      </p>
      <p>
        To illustrate this let's say your app has the following versions: 1.0.0,
        1.0.1, 1.2.0, 1.3.0.
      </p>
      <p>
        If you add an <b>Exact rule (=) for version 1.2.0</b>, your message will
        only be displayed to the users of the app which are on version 1.2.0.
        Other versions will remain unaffected.
      </p>
      <p>
        If you add a rule for <b>Lower than (&lt;) for version 1.2.0</b>, your
        message will apply to all the versions lower than 1.2.0 -- in this
        example 1.0.0, 1.0.1. Other versions will remain unaffected.
      </p>
      <p className="important-p">
        If you just implemented the SDK and you don't see your app version, that
        means, you have NOT started the app with the SDK integrated, or there
        are issues with the integration. In this case, check if the SDK is
        integrated properly -- if something is wrong, you'll see error messages
        in the console log.
      </p>

      <h3>Schedule</h3>
      <p>
        You need to pick a start date and time for your rule, and optionally and
        end date. Your message will only be displayed to your users in the
        selected timeframe, if you want to add a message for an indefinite
        amount of time, select your desired start date and leave the end date
        empty.
      </p>

      <h3>Publish</h3>
      <p>
        Once you click <b>Publish</b> the new message is added and your users
        will start seeing it when bringing the app to the foreground.
      </p>

      <p>
        Should you wish to disable the message, simply go to <b>Messages</b>,
        click on your desired message, and archive it, once a message is
        archived, it will have no effect on the users.
      </p>
      <br></br>
      <br></br>
      <p>
        For more details about the integrations, and the Android SDK check the{" "}
        <a href="https://github.com/semaphr/android" target="blank">
          documentation
        </a>
        , or if you have any questions write us an email at{" "}
        <a href="mailto:info@semaphr.com">info@semaphr.com</a>.
      </p>
    </div>
  );

  return {
    title: title,
    content: content,
    href: href,
    headerImage: blogPostImage,
    readTime: readTime,
    shortDescription: shortDescription,
  };
};

export default HowToBlockUsersFromAndroidApp;
