import React from "react";
import Asset1 from "../../../resources/img/for-teams-asset1.png";
import Asset1SmallPng from "../../../resources/img/for-teams-asset1-small.png";

import { ReactComponent as TextDecoration } from "../../../resources/img/for-teams-title-decoration.svg";
const ForTeams = () => {
  return (
    <div className="for-teams-page-background">
      <div className="container  for-teams-page">
        <div className="for-teams-title-wraper">
          <h1 className="for-teams-page-title">Semaphr for teams</h1>
          <h2 className="for-teams-page-subtitle">
            Invite your entire team and communicate better at{" "}
            <span className="for-page-decorated-text">
              scale. <TextDecoration />
            </span>
          </h2>
        </div>
        <div className="for-teams-page-content ">
          <div className="display-on-big">
            <img src={Asset1} alt="" className="img-fluid" />
          </div>

          <div className="display-on-small">
            <img
              style={{ maxWidth: "550px", width: "100%" }}
              src={Asset1SmallPng}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForTeams;
