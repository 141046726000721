import { useState } from "react";

import emptyBulletImage from "../../../resources/img/empty-bullet.svg";
import selectedBulletImage from "../../../resources/img/selected-bullet.svg";
import downGrayImage from "../../../resources/img/down-gray.svg";
import useComponentVisible from "../../../helpers/ComponentVisibility";

const DropdownOption = ({ value, selected, setSelected, className = null }) => {
  var image = emptyBulletImage;
  if (selected) {
    image = selectedBulletImage;
  }

  var containerClass = "message-drop-down-option";
  if (className) {
    containerClass += " " + className;
  }

  return (
    <div className={containerClass} onClick={() => setSelected(value)}>
      <div className="image">
        <img src={image} alt="selection" />
      </div>
      <div className="value">{value}</div>
    </div>
  );
};

const DropdownWithOptions = ({
  placeholder,
  values,
  selectedValue,
  setSelectedValue,
  capitalize = true,
  containerClass = null,
  editDisabled,
}) => {

  const handleOnClick = () => {
    if (editDisabled) {
      return
    }
    toggle();
  }
  var valueClass = "value";
  var valueText = selectedValue;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const toggle = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  if (!selectedValue) {
    valueClass = "placeholder";
    valueText = placeholder;
  }

  var currentContainerClass = "current-value-container";
  if (containerClass) {
    currentContainerClass += " " + containerClass;
  }

  var options = [];
  values.forEach((element) => {
    var value = element.toUpperCase();
    if (!capitalize) {
      value = element;
    }

    const item = (
      <DropdownOption
        value={value}
        selected={element === selectedValue}
        setSelected={setSelectedValue}
      />
    );
    options.push(item);
  });

  var dropDownItem;
  if (isComponentVisible) {
    dropDownItem = <div className="dropdown-list">{options}</div>;
    valueText = placeholder;
    valueClass = "placeholder";
  }

  return (
    <div ref={ref} className="message-drop-down" onClick={() => handleOnClick()}>
      <div className={currentContainerClass}>
        <div className={valueClass}>{valueText}</div>
        <div className="image">
          <img src={downGrayImage} className="down-image" alt="down" />
        </div>
      </div>
      {dropDownItem}
    </div>
  );
};

export default DropdownWithOptions;
