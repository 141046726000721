const CopyField = ({ extraClassName = null, value = null }) => {
  var className = "white-input-field-container copy-field";
  if (extraClassName) {
    className += " " + extraClassName;
  }

  return (
    <div className={className}>
      <div className="input">{value}</div>
    </div>
  );
};

export default CopyField;
