import searchImage from "../../../../resources/img/search.svg";
import $ from "jquery";

const SearchBar = ({ term, setTerm }) => {
  return (
    <div className="search-bar">
      <img src={searchImage} className="image" alt="search" />
      <input
        className="search"
        placeholder="Search"
        id="search-field"
        onChange={(val) => setTerm($("#search-field").val())}
      ></input>
    </div>
  );
};

export default SearchBar;
