import React from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";

const SiteFooter = () => {
  return (
    <div className="site-footer-cotainer">
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <LeftSide />
        </div>
        <div className="col">
          <RightSide />
        </div>
      </div>
      <div className="display-on-small">
        <div className="footer-copyright-wrapper">
          <p className="footer-copyright ">
            Copyright © 2023 <span>semaphr</span>. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SiteFooter;
