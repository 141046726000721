import $ from "jquery";
import { useState } from "react";

import { InputField } from "../../components/generic/InputField";
import { SimpleButton } from "../../components/generic/SimpleButton";
import { ActionButton } from "../../components/generic/ActionButton";
import { ErrorLabel } from "../../components/generic/ErrorLabel";
import { ContainerWithLogo } from "../../components/generic/ContainerWithLogo";
import {
  isNameValid,
  isEmailValid,
  isPasswordValid,
} from "../../helpers/ValidatorHelper";

import { useUserContext } from "../../components/model/context/UserContext";
import TrackingComponent from "../../components/tracking/TrackingComponent";

import {
  navigateToLogin,
  navigateToDashboard,
} from "../../helpers/NavigationHelper";
import Notifications from "../../helpers/Notifications";

const RegisterPage = ({ setLoading }) => {
  const [emailValid, setEmailValid] = useState(true);
  const [nameValid, setNameValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [registerEnabled, setRegisterEnabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const { createUser, navigateToDashboardIfPossible } = useUserContext();

  const onFieldsChange = () => {
    const name = $("#name").val();
    const email = $("#email").val();
    const password = $("#password").val();

    if (name.length > 0 && email.length > 0 && password.length > 0) {
      setRegisterEnabled(true);
    } else {
      setRegisterEnabled(false);
    }
  };

  const registerAction = () => {
    const name = $("#name").val();
    const email = $("#email").val();
    const password = $("#password").val();

    if (!isNameValid(name)) {
      setNameValid(false);
      return;
    }

    if (!isEmailValid(email)) {
      setEmailValid(false);
      return;
    }

    if (!isPasswordValid(password)) {
      setPasswordValid(false);
      return;
    }

    setNameValid(true);
    setEmailValid(true);
    setPasswordValid(true);

    setLoading(true);
    createUser(
      email,
      name,
      password,
      (response) => {
        // User created
        setLoading(false);
        navigateToDashboardIfPossible();
      },
      () => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  var errorContent;
  if (
    emailValid === false ||
    nameValid === false ||
    passwordValid === false ||
    showError
  ) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"The name, email or password are not valid."} />
      </div>
    );
  }

  const title = <div className="title text-left mt-24">Get started</div>;
  const subtitle = (
    <div className="subtitle text-left mt-8">Create your account now</div>
  );

  const separator = <div className="mt-60"></div>;

  const name = (
    <InputField
      placeholder={"Enter your full name"}
      id={"name"}
      type={"text"}
      error={!nameValid}
      onChange={onFieldsChange}
      value={null}
    />
  );

  const emailAddress = (
    <InputField
      placeholder={"Enter your email address"}
      id={"email"}
      type={"email"}
      onChange={onFieldsChange}
      error={!emailValid}
      value={null}
    />
  );

  const password = (
    <InputField
      placeholder={"Enter your password"}
      id={"password"}
      type={"password"}
      error={!passwordValid}
      onChange={onFieldsChange}
    />
  );

  const submit = (
    <ActionButton
      title={"Register"}
      action={registerAction}
      className="mt-60"
      active={registerEnabled}
    />
  );

  const disclaimer = (
    <div className="authentication-disclaimer">
      Have an account?{" "}
      <SimpleButton
        text={"Log in"}
        action={() => {
          navigateToLogin();
        }}
      />
    </div>
  );

  const components = [
    title,
    subtitle,
    separator,
    name,
    emailAddress,
    password,
    errorContent,
    submit,
    disclaimer,
  ];

  return (
    <div id="register" className="full-screen-item">
      <ContainerWithLogo components={components} />
      <TrackingComponent />
    </div>
  );
};

export default RegisterPage;
