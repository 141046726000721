import { useState } from "react";
import PlatformHeader from "../../header/PlatformHeader";
import SectionTitle from "../../generic/DashboardSectionTitle";
import WhiteInputField from "../../generic/WhiteInputField";
import { SimpleButton } from "../../../generic/SimpleButton";

import logoutIcon from "../../../../resources/img/logout.svg";
import { useUserContext } from "../../../model/context/UserContext";
import Notifications from "../../../../helpers/Notifications";
import {
  navigateToForgotPassword,
  navigateToLogin,
} from "../../../../helpers/NavigationHelper";
import LocalStorage from "../../../model/state/LocalStorageHelper";

const Profile = ({ setLoading }) => {
  const title = "Account";
  const showFilters = false;

  const header = <PlatformHeader title={title} showFilters={showFilters} />;

  const { currentUser, editUser } = useUserContext();
  const user = currentUser();

  const [name, setName] = useState(user.name);

  const resetPasswordAction = () => {
    LocalStorage.logoutUser();
    navigateToForgotPassword();
  };

  const logout = () => {
    LocalStorage.logoutUser();
    navigateToLogin();
  };

  const updateName = (name) => {
    setName(name);

    setLoading(true);
    editUser(
      name,
      (response) => {
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <div className="row">
        <div className="col one-column-field-size">
          <SectionTitle title={"Full name"} />
          <WhiteInputField
            placeholder={"Enter your full name"}
            value={name}
            onChange={(val) => {
              updateName(val);
            }}
            showEditIcon={true}
          />
        </div>
        <div className="col one-column-field-size">
          <SectionTitle title={"Email"} />
          <WhiteInputField
            placeholder={"Project name"}
            showEditIcon={false}
            readOnly={true}
            value={user.email}
            extraClass={"disable-input-field"}
          />
          <div className="copy-to-clipboard-container">
            <SimpleButton
              text={"Reset password"}
              action={resetPasswordAction}
            />
          </div>
        </div>
      </div>

      <div className="row mt-24">
        <div className="col-8 two-columns-fields-size">
          <div className="separator mt-24 mb-24"></div>
        </div>
      </div>

      <div className="row mt-24">
        <div className="col-8 two-columns-fields-size">
          <div className="logout-button-container">
            <button className="transparent-button">
              <div
                className="logout-button-items"
                onClick={() => {
                  logout();
                }}
              >
                <div className="image">
                  <img src={logoutIcon} alt={"logout"} />
                </div>
                <div className="text">Logout</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="profile">
      {header}
      {content}
    </div>
  );
};

export default Profile;
