
export const SocialMediaButton = ({ action, socialMediaIcon }) => {

    return (
        <button className='footer-social-media-button' onClick={action}>
            <div>
                {socialMediaIcon}
            </div>
        </button>
    )
}
