import React, { useState } from 'react'
import { ReactComponent as DownSvg } from "../../../resources/img/down-arrow.svg"
const FaqItem = ({ title, text }) => {

    const [expand, setExpand] = useState(false)

    return (
        <div className='faq-item-wrapper'>
            <div className='faq-title' onClick={() => setExpand(!expand)} >
                <p>{title} </p>
                <buton className="faq-arrow-button">
                    <DownSvg style={expand ? { rotate: "180deg" } : {}} />
                </buton>
            </div>
            {expand && <div className='faq-text'>
                <p>{text}</p>
            </div>}


        </div>
    )
}

export default FaqItem