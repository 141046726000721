import $ from "jquery";

import { InputField } from "../../components/generic/InputField";
import { SimpleButton } from "../../components/generic/SimpleButton";
import { ActionButton } from "../../components/generic/ActionButton";
import { ErrorLabel } from "../../components/generic/ErrorLabel";
import { ContainerWithLogo } from "../../components/generic/ContainerWithLogo";
import { createProjectAPICall } from "../../components/model/api/ProjectsService";
import { useEffect, useState } from "react";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const CreateNewProjectPage = ({
  setLoading,
  refreshCurrentUser,
  addProject,
  setAddProject,
}) => {
  const [showError, setShowError] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const onFieldsChange = () => {
    const projectName = $("#name").val();

    if (projectName.length > 2) {
      setButtonEnabled(true);
      return;
    }

    setButtonEnabled(false);
  };

  const createNewProject = () => {
    const projectName = $("#name").val();

    setLoading(true);
    createProjectAPICall(
      projectName,
      (response) => {
        setLoading(false);
        refreshCurrentUser();
      },
      (error) => {
        setLoading(false);
        setShowError(true);
      }
    );
  };

  var errorContent;
  if (showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"There was an error, please try again"} />
      </div>
    );
  }

  const title = (
    <div>
      <div className="title text-left mt-24">Create a new project</div>
    </div>
  );
  const subtitle = (
    <div className="subtitle text-left mt-8">
      You're ready to create a new project, but first let’s name it.
    </div>
  );

  const separator = <div className="mt-60"></div>;

  const projectName = (
    <InputField
      placeholder={"Enter your project name"}
      id={"name"}
      type={"text"}
      onChange={onFieldsChange}
      value={null}
    />
  );

  const submit = (
    <ActionButton
      title={"Continue"}
      action={createNewProject}
      className="mt-60"
      active={buttonEnabled}
    />
  );

  const components = [title, subtitle, separator, projectName, submit];

  return (
    <div id="login" className="full-screen-item">
      <ContainerWithLogo
        components={components}
        addClose={addProject}
        closeAction={() => {
          setAddProject(false);
        }}
      />
      <TrackingComponent />
    </div>
  );
};

export default CreateNewProjectPage;
