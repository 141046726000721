import React from "react";
import { SocialMediaButton } from "./SocialMediaButton";
import { ReactComponent as TwitterLogo } from "../../../resources/img/social-media/twitter-round.svg";
import { ReactComponent as LinkedInLogo } from "../../../resources/img/social-media/linkedin-round.svg";
import { ReactComponent as FacebookLogo } from "../../../resources/img/social-media/facebook-round.svg";
import { ReactComponent as GithubLogo } from "../../../resources/img/social-media/github-round.svg";
import { ReactComponent as InstagramLogo } from "../../../resources/img/social-media/instagram-round.svg";
import { navigateToAnotherSite } from "../../../helpers/NavigationHelper";

const SocialMediaWrapper = () => {
  const socialMediaButtons = [
    {
      action: () => navigateToAnotherSite("https://twitter.com/semaphr_"),
      socialMediaIcon: <TwitterLogo />,
    },
    {
      action: () =>
        navigateToAnotherSite("https://www.linkedin.com/company/semaphr/"),
      socialMediaIcon: <LinkedInLogo />,
    },
    {
      action: () => navigateToAnotherSite("https://github.com/semaphr"),
      socialMediaIcon: <GithubLogo />,
    },
  ];

  return (
    <div className="footer-social-media-wrapper">
      {socialMediaButtons.map((item) => (
        <SocialMediaButton
          action={item.action}
          socialMediaIcon={item.socialMediaIcon}
        />
      ))}
    </div>
  );
};

export default SocialMediaWrapper;
