const TextareaField = ({
  placeholderText,
  className = null,
  idValue,
  onChange,
  editDisabled
}) => {
  var currentClassName = "message-input-container";
  if (className) {
    currentClassName += " " + className;
  }
  var inputFieldClassName = "message-field-large";

  return (
    <div className={currentClassName}>
      <textarea
        disabled={editDisabled}
        className={inputFieldClassName}
        placeholder={placeholderText}
        id={idValue}
        onChange={() => {
          onChange();
        }}
      ></textarea>
    </div>
  );
};

export default TextareaField;
