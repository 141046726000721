import gearImage from "../../../../resources/img/message.png";
import { ActionButton } from "../../../generic/ActionButton";

const AddMessage = ({ app, isWeb, configureAction }) => {
  var title = "Add a first message for your app";
  const subtitle =
    "By keeping your users informed and minimizing downtime, semaphr can help improve customer satisfaction and loyalty.";

  var buttonTitle = "Add message";

  if (isWeb) {
    title = "Add a first message for your app";
    buttonTitle = "Add message";
  }

  return (
    <div className="configure-app-view">
      <div className="configure-app-container">
        <div className="message-image-container">
          <img src={gearImage} className="message-image" alt="gear" />
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <div className="button-container">
          <ActionButton
            title={buttonTitle}
            action={configureAction}
            className="mt-48"
            isSlim={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AddMessage;
