import { ANDROID, WEB, IOS } from "../../../constants/PlatformConstants";

import backImage from "../../../resources/img/back.svg";
import SelectorButton from "../generic/SelectorButton";

const PlatformSelector = ({ selectedValue, setSelectedValue }) => {
  const web = (
    <SelectorButton
      title={"Web"}
      selectionKey={WEB}
      isSelected={selectedValue === WEB}
      selectValue={setSelectedValue}
    />
  );

  const iOS = (
    <SelectorButton
      title={"iOS"}
      selectionKey={IOS}
      isSelected={selectedValue === IOS}
      selectValue={setSelectedValue}
    />
  );

  const android = (
    <SelectorButton
      title={"Android"}
      selectionKey={ANDROID}
      isSelected={selectedValue === ANDROID}
      selectValue={setSelectedValue}
    />
  );

  return (
    <div className="platform-selector">
      <div className="container">
        <div className="row">
          {/* Remove web for now */}
          {/* <div className="col-4 no-padding">{web}</div> */}
          <div className="col-6 no-padding">{iOS}</div>
          <div className="col-6 no-padding">{android}</div>
        </div>
      </div>
    </div>
  );
};

const BackButton = ({ action }) => {
  return (
    <div className="platform-header-back-button-container">
      <button className="transparent-button" onClick={() => action()}>
        <img src={backImage} alt="back" />
      </button>
    </div>
  );
};

const PlatformHeader = ({
  title,
  showFilters,
  selectedFilter,
  hasSelectedFilter,
  showBack = false,
  backAction = null,
}) => {
  var filters;
  if (showFilters) {
    filters = (
      <PlatformSelector
        selectedValue={selectedFilter}
        setSelectedValue={hasSelectedFilter}
      />
    );
  }

  var back;
  if (showBack) {
    back = <BackButton action={backAction} />;
  }

  return (
    <div className="platform-header">
      <div className="left-container">
        {back}
        <div className="title">{title}</div>
      </div>
      <div className="filters">{filters}</div>
    </div>
  );
};

export default PlatformHeader;
