import { useState } from "react";
import PlatformHeader from "../../../header/PlatformHeader";
import SectionTitle from "../../../generic/DashboardSectionTitle";
import WhiteInputField from "../../../generic/WhiteInputField";

import { SimpleButton } from "../../../../generic/SimpleButton";

import iosImage from "../../../../../resources/img/ios.svg";
import webImage from "../../../../../resources/img/web.svg";
import androidImage from "../../../../../resources/img/android.svg";

import PlatformType from "./elements/PlatformType";
import CopyField from "../../../generic/CopyField";
import { ActionButton } from "../../../../generic/ActionButton";
import GenericSelector from "../../../generic/GenericSelector";
import { isIdentifierValid } from "../../../../../helpers/ValidatorHelper";
import { addApplicationAPICall } from "../../../../model/api/ApplicationsService";
import Notifications from "../../../../../helpers/Notifications";

const npmCodeToCopy = () => {
  return "pod install";
};

const scriptCodeToCopy = () => {
  return "spm install";
};

const sdkCodeToCopy = () => {
  return "sdk install";
};

const NPMCode = () => {
  return (
    <div>
      <span className="code-highlighted">npm </span>'KillSwitch'
    </div>
  );
};

const ScriptTagCode = () => {
  return (
    <div>
      <span className="code-highlighted">script </span>'KillSwitch'
    </div>
  );
};

const PlatformTypeComponent = () => {
  return (
    <div className="row">
      <div className="col-8 two-columns-fields-size">
        <PlatformType image={webImage} title={"Web"} />
        <div className="separator mt-16"> </div>
      </div>
    </div>
  );
};

const RegisterTheAppComponent = ({ identifier, setIdentifier }) => {
  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Register the app"} />
        <div className="integration-description-small">
          Enter the Web address of the application that you wish to link to this
          account. For more info, check the <a href="#">documentation here</a>.
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"Web address"} />
        <div className="integration-description-small">
          <WhiteInputField
            readOnly={false}
            id={"identifier"}
            placeholder={"Enter the Web address"}
            showEditIcon={false}
            value={identifier}
            onChange={(val) => setIdentifier(val)}
          />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const AddTheAppComponent = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("npm");

  var instruction = <ScriptTagCode />;
  var codeToCopy = scriptCodeToCopy();

  if (selectedPlatform === "npm") {
    instruction = <NPMCode />;
    codeToCopy = npmCodeToCopy();
  }

  const code = (
    <div className="sdk-code-field">
      <div className="code-field">{instruction}</div>
    </div>
  );

  const copyField = <CopyField value={code} />;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy);
  };

  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Add the SDK"} />
        <div className="integration-description-small">
          Add the following line to your Pods file. <br></br>For additional
          details, see <a href="#">the developer documentation</a>.
        </div>
        <div className="library-selector-container">
          <GenericSelector
            extraClassName="library-selector"
            values={["npm", "Script tag"]}
            selectedValue={selectedPlatform}
            setSelectedValue={setSelectedPlatform}
          />
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"SDK"} />
        <div className="integration-description-small">{copyField}</div>
        <div className="copy-to-clipboard-container mb-16">
          <SimpleButton text={"Copy to clipboard"} action={copyToClipboard} />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const AddInitialisationCodeComponent = ({ saveEnabled, saveAction }) => {
  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col"></div>
      <div className="col  one-column-large-field-size">
        <ActionButton
          title={"Save"}
          action={saveAction}
          className="mb-16 mt-16"
          active={saveEnabled}
          isSlim={true}
        />
      </div>
    </div>
  );
};

const ConfigureIntegrationWeb = ({
  setLoading,
  dismissAction,
  selectedProject,
}) => {
  const title = "Configure integration";
  const showFilters = false;
  const [identifier, setIdentifier] = useState(null);

  const backAction = () => {
    dismissAction();
  };

  const saveAction = () => {
    const projectID = selectedProject.id;

    setLoading(true);
    addApplicationAPICall(
      identifier,
      "web",
      projectID,
      (response) => {
        setLoading(false);
        Notifications.showSuccess("Application added");
        dismissAction();
      },
      (err) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const identifierValid = isIdentifierValid(identifier);

  const header = (
    <PlatformHeader
      title={title}
      showFilters={showFilters}
      showBack={true}
      backAction={backAction}
    />
  );

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <PlatformTypeComponent />
      <RegisterTheAppComponent
        identifier={identifier}
        setIdentifier={setIdentifier}
      />
      <AddTheAppComponent />
      <AddInitialisationCodeComponent
        saveEnabled={identifierValid}
        saveAction={saveAction}
      />
    </div>
  );

  return (
    <div id="configure-web">
      {header}
      {content}
    </div>
  );
};

export default ConfigureIntegrationWeb;
