import { POST, GET, DELETE, APIVersion, PUT } from "./APIHelper";

export const createProjectAPICall = (name, onSuccess, onError) => {
  const data = {
    name: name,
  };

  POST(APIVersion + "/projects/create", data, onSuccess, onError);
};

export const deleteProjectAPICall = (id, onSuccess, onError) => {
  const path = APIVersion + "/projects/" + id;

  DELETE(path, onSuccess, onError);
};

export const getAllProjectsAPICall = (onSuccess, onError) => {
  GET(APIVersion + "/projects", onSuccess, onError);
};

export const editProjectAPICall = (id, name, onSuccess, onError) => {
  const data = {
    name: name,
  };

  const path = APIVersion + "/projects/" + id;
  PUT(path, data, onSuccess, onError);
};

export const getMembersAPICall = (id, onSuccess, onError) => {
  const path = APIVersion + "/projects/" + id + "/members";
  GET(path, onSuccess, onError);
};

export const inviteMemberAPICall = (
  projectID,
  email,
  role,
  onSuccess,
  onError
) => {
  const path = APIVersion + "/projects/" + projectID + "/add_member";

  const data = {
    email: email,
    role: role,
  };

  POST(path, data, onSuccess, onError);
};

export const removeMemberAPICall = (projectID, email, onSuccess, onError) => {
  const path = APIVersion + "/projects/" + projectID + "/remove_member";

  const data = {
    email: email,
  };

  POST(path, data, onSuccess, onError);
};
