const MessageSectionTitle = ({ title }) => {
  return (
    <div className="message-section">
      <div className="title">{title}</div>
      <div className="separator"></div>
    </div>
  );
};

export default MessageSectionTitle;
