import { POST, GET, DELETE, APIVersion, PUT } from "./APIHelper";

export const addApplicationAPICall = (
  identifier,
  platform,
  projectID,
  onSuccess,
  onError
) => {
  const data = {
    identifier: identifier,
    platform: platform,
  };

  const path = APIVersion + "/projects/" + projectID + "/applications/create";

  POST(path, data, onSuccess, onError);
};

export const deleteApplicationAPICall = (
  applicationID,
  projectID,
  onSuccess,
  onError
) => {
  const path =
    APIVersion + "/projects/" + projectID + "/applications/" + applicationID;

  DELETE(path, onSuccess, onError);
};

export const editApplicationAPICall = (
  identifier,
  platform,
  applicationID,
  projectID,
  onSuccess,
  onError
) => {
  const data = {
    identifier: identifier,
    platform: platform,
  };

  const path =
    APIVersion + "/projects/" + projectID + "/applications/" + applicationID;

  PUT(path, data, onSuccess, onError);
};

export const versionsForApplicationAPICall = (
  projectID,
  platform,
  onSuccess,
  onError
) => {
  const path = APIVersion + "/projects/" + projectID + "/applications/versions";

  const data = {
    platform: platform,
  };

  POST(path, data, onSuccess, onError);
};
