import React from "react";
import { SiteHeaderActionButton } from "./SiteHeaderActionButton";
import {
  navigateToBlog,
  navigateToHomeWithPath,
  navigateToRegister,
} from "../../../helpers/NavigationHelper";

const SiteHeaderButtons = () => {
  return (
    <div className="site-header-buttons-container">
      <a
        href="https://github.com/semaphr"
        className="site-header-normal-link"
        target="_blank"
      >
        Documentation
      </a>
      <a className="site-header-normal-link" onClick={() => navigateToBlog()}>
        Blog
      </a>
      <a
        className="site-header-normal-link"
        onClick={() => navigateToHomeWithPath("#pricing")}
      >
        Pricing
      </a>
      <a
        className="site-header-normal-link"
        onClick={() => navigateToHomeWithPath("#faq")}
      >
        FAQs
      </a>

      <a href="/dashboard" className="site-header-bold-link">
        Log in
      </a>
      <SiteHeaderActionButton
        title={"Get started →"}
        action={() => {
          navigateToRegister();
        }}
      />
    </div>
  );
};

export default SiteHeaderButtons;
