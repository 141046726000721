import $ from "jquery";
import TrackingComponent from "../../components/tracking/TrackingComponent";
import { blogPostsArray } from "../../components/blog/BlogPostBuilder";
import BlogPostItem from "../../components/generic/BlogPostItem";
import SiteHeader from "../../components/site/header/SiteHeader";
import SiteFooter from "../../components/site/footer/SiteFooter";

const BlogPage = ({ setLoading }) => {
  let blogPosts = [];
  blogPostsArray().forEach((element) => {
    const postComponent = (
      <>
        <div className="col-lg-4">
          <BlogPostItem blogPost={element()} />
        </div>
      </>
    );

    blogPosts.push(postComponent);
  });

  return (
    <div>
      <SiteHeader />
      <div className="blog-page white">
        <div className="container mb-60">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-title">Semaphr Blog</div>
              <div className="blog-subtitle">
                The latest in app updates, industry trends, and semaphr news.
              </div>
            </div>
          </div>

          <div className="row">{blogPosts}</div>
        </div>

        <div className="pb-60"></div>
      </div>
      <SiteFooter />
      <TrackingComponent />
    </div>
  );
};

export default BlogPage;
