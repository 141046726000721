const ConfigureButton = ({ action }) => {
  return (
    <div className="configure-button-container">
      <button className="configure-button" onClick={action}>
        Configure
      </button>
    </div>
  );
};

export default ConfigureButton;
