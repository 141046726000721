import { useState } from "react";
import PlatformHeader from "../../../header/PlatformHeader";
import SectionTitle from "../../../generic/DashboardSectionTitle";
import WhiteInputField from "../../../generic/WhiteInputField";

import { SimpleButton } from "../../../../generic/SimpleButton";

import iosImage from "../../../../../resources/img/ios.svg";
import webImage from "../../../../../resources/img/web.svg";
import androidImage from "../../../../../resources/img/android.svg";

import PlatformType from "./elements/PlatformType";
import CopyField from "../../../generic/CopyField";
import { ActionButton } from "../../../../generic/ActionButton";
import GenericSelector from "../../../generic/GenericSelector";
import { isIdentifierValid } from "../../../../../helpers/ValidatorHelper";
import { addApplicationAPICall } from "../../../../model/api/ApplicationsService";
import Notifications from "../../../../../helpers/Notifications";

const podCodeToCopy = () => {
  return "pod 'Semaphr'";
};

const spmCodeToCopy = () => {
  return "https://github.com/semaphr/ios";
};

const reactCodeToCopy = () => {
  return "react install";
};

const sdkCodeToCopy = (project) => {
  return 'Semaphr.configure(APIKey:"' + project.api_key + '")';
};

const PodCode = () => {
  return (
    <div>
      <span className="code-highlighted">pod </span>'Semaphr'
    </div>
  );
};

const SPMCode = () => {
  return (
    <div>
      <div>
        <span className="code-highlighted">https://github.com/semaphr/ios</span>
      </div>
    </div>
  );
};

const ReactCode = () => {
  return (
    <div>
      <span className="code-highlighted">react </span>'KillSwitch'
    </div>
  );
};

const SDKCode = () => {
  return (
    <div>
      <span className="code-highlighted">import </span>Semaphr<br></br>
      <br></br>
      <span className="code-highlighted">Semaphr</span>.configure(APIKey: KEY){" "}
      <br></br>
    </div>
  );
};

const PlatformTypeComponent = () => {
  return (
    <div className="row">
      <div className="col-8 two-columns-fields-size">
        <PlatformType image={iosImage} title={"iOS"} />
        <div className="separator mt-16"> </div>
      </div>
    </div>
  );
};

const RegisterTheAppComponent = ({ identifier, setIdentifier }) => {
  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Register the app"} />
        <div className="integration-description-small">
          Enter the bundle ID of the application that you wish to link to this
          account. The bundle ID can be found by following the{" "}
          <a
            href="https://developer.apple.com/documentation/appstoreconnectapi/bundle_ids"
            target="blank"
          >
            steps here
          </a>
          .
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"Bundle ID"} />
        <div className="integration-description-small">
          <WhiteInputField
            readOnly={false}
            id={"identifier"}
            placeholder={"Enter the bundle ID"}
            showEditIcon={false}
            value={identifier}
            onChange={(val) => setIdentifier(val)}
          />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const AddTheAppComponent = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("SPM");

  var instruction = <PodCode />;
  var codeToCopy = podCodeToCopy();

  if (selectedPlatform === "SPM") {
    instruction = <SPMCode />;
    codeToCopy = spmCodeToCopy();
  }

  if (selectedPlatform === "React") {
    instruction = <ReactCode />;
    codeToCopy = reactCodeToCopy();
  }

  const code = (
    <div className="sdk-code-field">
      <div className="code-field">{instruction}</div>
    </div>
  );

  const copyField = <CopyField value={code} />;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy);
  };

  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Add the SDK"} />
        <div className="integration-description-small">
          Integrate the SDK. <br></br>For additional details, see{" "}
          <a href="https://github.com/semaphr/ios" target="_blank">
            the developer documentation
          </a>
          .
        </div>
        <div className="library-selector-container">
          <GenericSelector
            extraClassName="library-selector"
            values={["SPM", "Cocoapods"]}
            selectedValue={selectedPlatform}
            setSelectedValue={setSelectedPlatform}
          />
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"SDK"} />
        <div className="integration-description-small">{copyField}</div>
        <div className="copy-to-clipboard-container mb-16">
          <SimpleButton text={"Copy to clipboard"} action={copyToClipboard} />
        </div>
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const AddInitialisationCodeComponent = ({
  saveEnabled,
  saveAction,
  selectedProject,
}) => {
  const code = (
    <div className="sdk-code-field">
      <div className="code-field">
        <SDKCode />
      </div>
    </div>
  );

  const copyField = (
    <CopyField value={code} extraClassName={"copy-field-large "} />
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sdkCodeToCopy(selectedProject));
  };

  return (
    <div className="row mt-16 two-columns-fields-size">
      <div className="col one-column-small-field-size">
        <SectionTitle title={"Add initialisation code"} />
        <div className="integration-description-small">
          Save time and ensuring required components are in place.
        </div>
      </div>

      <div className="col one-column-large-field-size">
        <SectionTitle title={"Code"} />
        <div className="integration-description-small">{copyField}</div>
        <div className="copy-to-clipboard-container mb-16">
          <SimpleButton text={"Copy to clipboard"} action={copyToClipboard} />
        </div>

        <ActionButton
          title={"Save"}
          action={saveAction}
          className="mb-16 mt-16"
          active={saveEnabled}
          isSlim={true}
        />
      </div>

      <div className="separator mt-16"> </div>
    </div>
  );
};

const ConfigureIntegrationIos = ({
  setLoading,
  dismissAction,
  selectedProject,
}) => {
  const title = "Configure integration";
  const showFilters = false;
  const [identifier, setIdentifier] = useState(null);

  console.log("selecter pro", selectedProject);

  const backAction = () => {
    dismissAction();
  };

  const saveAction = () => {
    const projectID = selectedProject.id;

    setLoading(true);
    addApplicationAPICall(
      identifier,
      "ios",
      projectID,
      (response) => {
        setLoading(false);
        Notifications.showSuccess("Application added");
        dismissAction();
      },
      (err) => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  const identifierValid = isIdentifierValid(identifier);

  const header = (
    <PlatformHeader
      title={title}
      showFilters={showFilters}
      showBack={true}
      backAction={backAction}
    />
  );

  const content = (
    <div className="no-margins-container container platform-margins mt-24">
      <PlatformTypeComponent />
      <RegisterTheAppComponent
        identifier={identifier}
        setIdentifier={setIdentifier}
      />
      <AddTheAppComponent />
      <AddInitialisationCodeComponent
        saveEnabled={identifierValid}
        saveAction={saveAction}
        selectedProject={selectedProject}
      />
    </div>
  );

  return (
    <div id="configure-ios">
      {header}
      {content}
    </div>
  );
};

export default ConfigureIntegrationIos;
