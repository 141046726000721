import { useState } from "react";
import calendarImage from "../../../../../../resources/img/calendar.svg";
import closeImage from "../../../../../../resources/img/close.svg";
import $ from "jquery";
import moment from "moment";

const MessageDatePicker = ({ pickedDate, setPickedDate, className = null, editDisabled }) => {
  const [shouldPickDate, setShouldPickDate] = useState(false);

  const date = moment();
  var currentDateToDisplay = date.format("yyyy-MM-DDTH:mm");
  if (pickedDate) {
    currentDateToDisplay = moment(pickedDate).format("yyyy-MM-DDTH:mm");
  }

  var textClass = "text";
  var text;
  if (!pickedDate) {
    text = "Choose...";
    textClass = "placeholder";
  }

  const toggle = () => {
    if (editDisabled) {
      return
    }

    setPickedDate(date.toDate());

    setShouldPickDate(!shouldPickDate);
  };

  const discardDate = () => {
    if (editDisabled) {
      return
    }
    toggle();
    setPickedDate(null);
  };

  var containerClass = "messages-date-picker-container";
  if (className) {
    containerClass += " " + className;
  }

  var currentValues = (
    <div
      className="date-picker-items"
      onClick={() => {
        toggle();
      }}
    >
      <div className={textClass}>{text}</div>
      <div className="image">
        <img src={calendarImage} alt="calendar" />
      </div>
    </div>
  );

  var dateTimeChanged = (date) => {
    const timestamp = date.target.value;
    const pickedDate = new Date(timestamp);
    setPickedDate(pickedDate);

    console.log("set date", pickedDate);
  };

  if (pickedDate || shouldPickDate) {
    currentValues = (
      <div className="date-picker-items">
        <div className="input">
          <input
            id="date-time"
            type="datetime-local"
            className="date-time-input"
            onChange={dateTimeChanged}
            value={currentDateToDisplay}
            disabled={editDisabled}
          ></input>
        </div>
        <div
          className="close-image"
          onClick={() => {
            discardDate();
          }}
        >
          <img
            src={closeImage}
            alt="close"
            className="date-picker-close-icon"
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={containerClass}>{currentValues}</div>
    </div>
  );
};

export default MessageDatePicker;
