import React from "react";
import desktop1 from "../../../resources/img/dashboard.png";
import desktop1Small from "../../../resources/img/Desktop-1-small.png";

const Fold3 = () => {
  return (
    <div className="container fold3-page">
      <h1 className="fold3-title">Efficient app versions management</h1>
      <h1 className="how-work-page-subtitle">
        One platform to manage all your mobile versions, block access, notify
        users, or urge them to update. Works with iOS and Android apps,
        regardless of their tech stack.
      </h1>
      <img src={desktop1} alt="asset" className="fold-3-asset display-on-big" />
      <img
        src={desktop1Small}
        alt="asset"
        className="fold-3-asset display-on-small"
      />
    </div>
  );
};

export default Fold3;
