const BlogPostItem = ({ blogPost, noDescription = false }) => {
  let desc;
  if (noDescription === false) {
    desc = (
      <div className="blog-post-subtitle">{blogPost.shortDescription}</div>
    );
  }

  return (
    <a className="blog-a" href={blogPost.href}>
      <div className="blog-post">
        <div className="blog-post-image-container">
          <img
            src={blogPost.headerImage}
            className="blog-post-image"
            alt="blog-post"
          />
        </div>
        <div className="blog-post-title">{blogPost.title}</div>
        {desc}
      </div>
    </a>
  );
};

export default BlogPostItem;
