export const PricingCardButton = ({
  title,
  className = null,
  action,
  active = true,
  isSlim = false,
  secondaryButton = false,
}) => {
  var containerClass = `small-action-button-container-full ${className}`;
  var actionButtonClass = "pricing-card-button";

  if (!active) {
    containerClass += " disabled";
  }

  if (isSlim) {
    containerClass += " slim-button";
    actionButtonClass += " slim-action-button";
  }

  if (secondaryButton) {
    actionButtonClass += " pricing-card-button-secondary";
  }

  return (
    <div className={containerClass}>
      <button onClick={action} className={actionButtonClass} disabled={!active}>
        {title}
      </button>
    </div>
  );
};
