import React from "react";
import { ActionButton } from "../../generic/ActionButton";
import Asset1 from "../../../resources/img/reach-users-asset2.png";
import Asset1Small from "../../../resources/img/reach-users-asset-small.png";
import { navigateToRegister } from "../../../helpers/NavigationHelper";

const ReachYourUsers = () => {
  return (
    <div className="reach-users-page-background">
      <div className="container reach-users-page">
        <div className="col mb-5 reach-users-text-container">
          <h1 className="reach-users-page-title">
            Reach your users everywhere, anytime.
          </h1>
          <p className="reach-users-page-text">
            Your messages will be displayed to all the apps with the selected
            version, regardless of the status of your backend service or your
            app's functionality.
          </p>

          <div className="display-on-big-flex">
            <ActionButton
              title={"Get started for free"}
              className={"reach-users-page-button"}
              isSlim
              action={() => navigateToRegister()}
            />
          </div>

          <div className="display-on-small">
            <ActionButton
              title={"Get started"}
              className={"reach-users-page-button"}
              isSlim
              action={() => navigateToRegister()}
            />
          </div>
        </div>

        <div className="col offset-1 reach-users-asset-container display-on-big h-100 ">
          <img
            style={{ maxHeight: "761px" }}
            src={Asset1}
            alt="asset"
            className=""
          />
        </div>

        <div className="col reach-users-asset-container display-on-small ">
          <img
            style={{ maxWidth: "330px" }}
            src={Asset1Small}
            alt="asset"
            className=""
          />
        </div>
      </div>
    </div>
  );
};

export default ReachYourUsers;
