import { POST, GET, PATCH } from "./APIHelper";

export const signIn = (email, password, onSuccess, onError) => {
  const data = {
    grant_type: "password",
    email: email,
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  };

  POST("/oauth/token", data, onSuccess, onError);
};

export const createAccountAPICall = (
  email,
  name,
  password,
  onSuccess,
  onError
) => {
  const data = {
    name: name,
    email: email,
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID,
  };

  POST(
    process.env.REACT_APP_API_PATH + "/users/create",
    data,
    onSuccess,
    onError
  );
};

export const acceptInviteAPICall = (
  invitationToken,
  name,
  password,
  onSuccess,
  onError
) => {
  const data = {
    name: name,
    invitation_token: invitationToken,
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID,
  };

  console.log("data", data);

  POST(
    process.env.REACT_APP_API_PATH + "/users/accept_invite",
    data,
    onSuccess,
    onError
  );
};

export const loginAPICall = (email, password, onSuccess, onError) => {
  const data = {
    email: email,
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    grant_type: "password",
  };

  POST("/oauth/token", data, onSuccess, onError);
};

export const resetPasswordAPICall = (email, onSuccess, onError) => {
  const data = {
    email: email,
  };

  POST(
    process.env.REACT_APP_API_PATH + "/users/reset_password",
    data,
    onSuccess,
    onError
  );
};

export const changePasswordAPICall = (token, password, onSuccess, onError) => {
  const data = {
    new_password: password,
    reset_token: token,
  };

  POST(
    process.env.REACT_APP_API_PATH + "/users/change_password",
    data,
    onSuccess,
    onError
  );
};

export const addSubscriptionAPICall = (productType, onSuccess, onError) => {
  const data = {
    product_type: productType,
  };

  POST(
    process.env.REACT_APP_API_PATH + "/users/create_subscription_session",
    data,
    onSuccess,
    onError
  );
};

export const fetchSubscriptionAPICall = (onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH + "/users/subscription",
    onSuccess,
    onError
  );
};

export const fetchStripeDashboardAPICall = (onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH + "/users/stripe_dashboard_url",
    onSuccess,
    onError
  );
};

export const pauseSubscriptionAPICall = (onSuccess, onError) => {
  POST(
    process.env.REACT_APP_API_PATH + "/users/pause_subscription",
    null,
    onSuccess,
    onError
  );
};

export const resumeSubscriptionAPICall = (onSuccess, onError) => {
  POST(
    process.env.REACT_APP_API_PATH + "/users/resume_subscription",
    null,
    onSuccess,
    onError
  );
};

export const cancelSubscriptionAPICall = (onSuccess, onError) => {
  POST(
    process.env.REACT_APP_API_PATH + "/users/cancel_subscription",
    null,
    onSuccess,
    onError
  );
};

export const currentUserAPICall = (onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH + "/users/current_user_details",
    onSuccess,
    onError
  );
};

export const subscriptionDetailsAPICall = (onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH + "/users/subscription_details",
    onSuccess,
    onError
  );
};

export const editUserAPICall = (name, onSuccess, onError) => {
  const data = {
    name: name,
  };

  PATCH(
    process.env.REACT_APP_API_PATH + "/users/edit_user",
    data,
    onSuccess,
    onError
  );
};
