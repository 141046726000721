import HowToBlockUsersFromApp from "./content/HowToBlockUsersFromApp";
import HowToForceAnUpdate from "./content/HowToForceAnUpdate";
import WhyNeedAKillSwitch from "./content/WhyNeedAKillSwitch";
import HowToNotifyiOSUsers from "./content/HowToNotifyiOSUsers";
import HowToForceAnUpdateAndroid from "./content/HowToForceAnUpdateAndroid";
import HowToBlockUsersFromAndroidApp from "./content/HowToBlockUsersFromAndroidApp";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const blogPostsArray = () => {
  const returnArray = [
    WhyNeedAKillSwitch,
    HowToForceAnUpdate,
    HowToBlockUsersFromApp,
    HowToForceAnUpdateAndroid,
    HowToBlockUsersFromAndroidApp,
  ];

  return shuffle(returnArray);
};

export const generateTwoRandomBlogPostsFor = (post) => {
  let returnArray = [];
  const blogPostArrays = blogPostsArray();

  blogPostArrays.forEach((element) => {
    if (element().href !== post.href) {
      returnArray.push(element);
    }

    if (returnArray.length === 2) {
      return returnArray;
    }
  });

  return returnArray;
};
