import SiteFooter from "../../components/site/footer/SiteFooter";
import SiteHeader from "../../components/site/header/SiteHeader";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const PrivacyPolicyPage = ({ setLoading }) => {
  return (
    <div>
      {" "}
      <SiteHeader />
      <div className="blog-page white">
        <div className="container mb-60">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-title mb-60">Privacy Policy</div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              Effective date: January 01, 2023
              <br></br>
              <br></br>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data. We use your data
              to provide and improve the Service. By using the Service, you
              agree to the collection and use of information in accordance with
              this policy. Unless otherwise defined in this Privacy Policy,
              terms used in this Privacy Policy have the same meanings as in our
              Terms and Conditions.
              <br></br>
              <br></br>
              <br></br>
              <span className="bold-text">Information Collection And Use</span>
              <br></br>
              <br></br>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
              <br></br>
              <br></br>
              Types of Data Collected:
              <br></br>
              <br></br>
              Personal Data
              <br></br>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information is limited to: email address.
              <br></br>
              <br></br>
              Usage Data
              <br></br>
              We do not collect any other data from your device sensors (such as
              camera, microphone, screens). None of the actions in our mobile
              application are tracked, recorded or transmited over the internet.
              <br></br>
              <br></br>
              Use of Data
              <br></br>
              We use the collected data for various purposes: to provide
              customer care and support, to be able to answer to customers
              inquiries.
              <br></br>
              <br></br>
              Transfer Of Data
              <br></br>
              Your information, including Personal Data, may be transferred to
              and maintained on computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ than those from your jurisdiction.
              If you are located outside Romania and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to Romania and process it there. Your
              consent to this Privacy Policy followed by your submission of such
              information represents your agreement to that transfer. We will
              take all steps reasonably necessary to ensure that your data is
              treated securely and in accordance with this Privacy Policy and no
              transfer of your Personal Data will take place to an organization
              or a country unless there are adequate controls in place including
              the security of your data and other personal information.
              <br></br>
              <br></br>
              <br></br>
              <span className="bold-text">Disclosure Of Data</span>
              <br></br>
              <br></br>
              Legal Requirements
              <br></br>
              We may disclose your Personal Data in the good faith belief that
              such action is necessary to: to comply with a legal obligation, to
              protect and defend the rights or property of Semaphr, to prevent
              or investigate possible wrongdoing in connection with the service,
              to protect the personal safety of users of the service or the
              public, to protect against legal liability.
              <br></br>
              <br></br>
              Security Of Data
              <br></br>
              The security of your data is important to us, but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
              <br></br>
              <br></br>
              Service Providers
              <br></br>
              We may employ third party companies and individuals to facilitate
              our Service ("Service Providers"), to provide the Service on our
              behalf, to perform Service-related services or to assist us in
              analyzing how our Service is used. These third parties have access
              to your Personal Data only to perform these tasks on our behalf
              and are obligated not to disclose or use it for any other purpose.
              <br></br>
              <br></br>
              Links To Other Sites
              <br></br>
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit. We have no control over
              and assume no responsibility for the content, privacy policies or
              practices of any third party sites or services.
              <br></br>
              <br></br>
              <br></br>
              <span className="bold-text">Children's Privacy</span>
              <br></br>
              <br></br>
              Our Service does not address anyone under the age of 18
              ("Children"). We do not knowingly collect personally identifiable
              information from anyone under the age of 18. If you are a parent
              or guardian and you are aware that your Children has provided us
              with Personal Data, please contact us. If we become aware that we
              have collected Personal Data from children without verification of
              parental consent, we take steps to remove that information from
              our servers.
              <br></br>
              <br></br>
              <br></br>
              <span className="bold-text">Changes To This Privacy Policy</span>
              <br></br>
              <br></br>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
        <div className="pb-60"></div>
      </div>
      <TrackingComponent />
      <SiteFooter />
    </div>
  );
};

export default PrivacyPolicyPage;
