import $ from "jquery";

import { InputField } from "../../components/generic/InputField";
import { SimpleButton } from "../../components/generic/SimpleButton";
import { ActionButton } from "../../components/generic/ActionButton";
import { ErrorLabel } from "../../components/generic/ErrorLabel";
import { ContainerWithLogo } from "../../components/generic/ContainerWithLogo";
import {
  navigateToRegister,
  navigateToForgotPassword,
} from "../../helpers/NavigationHelper";
import { useState, useEffect } from "react";
import { useUserContext } from "../../components/model/context/UserContext";

import { isEmailValid, isPasswordValid } from "../../helpers/ValidatorHelper";
import Notifications from "../../helpers/Notifications";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const LoginPage = ({ setLoading }) => {
  const [loginEnabled, setLoginEnabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const { loginUser, navigateToDashboardIfPossible, fetchCurrentUser } =
    useUserContext();

  const onFieldsChange = () => {
    const email = $("#email").val();
    const password = $("#password").val();

    if (email.length > 0 && password.length > 0) {
      setLoginEnabled(true);
    } else {
      setLoginEnabled(false);
    }
  };

  const onLogIn = () => {
    const email = $("#email").val();
    const password = $("#password").val();

    if (email.length > 0 && password.length > 0) {
      setLoginEnabled(true);
    } else {
      setLoginEnabled(false);
    }

    if (!isEmailValid(email)) {
      setShowError(true);
      return;
    }

    if (!isPasswordValid(password)) {
      setShowError(true);
      return;
    }

    setLoading(true);
    loginUser(
      email,
      password,
      (response) => {
        setLoading(false);
        // User is authenticated go to dashboard
        navigateToDashboardIfPossible();
      },
      () => {
        setLoading(false);
        setShowError(true);
      }
    );
  };

  const handleAutoLoginIfNeeded = () => {
    navigateToDashboardIfPossible();
  };

  useEffect(() => {
    handleAutoLoginIfNeeded();
  }, []);

  var errorContent;
  if (showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"Password or email is incorrect."} />
      </div>
    );
  }

  const title = <div className="title text-left mt-24">Welcome back</div>;
  const subtitle = (
    <div className="subtitle text-left mt-8">
      Log in now to access your account
    </div>
  );

  const separator = <div className="mt-60"></div>;

  const emailAddress = (
    <InputField
      placeholder={"Enter your email address"}
      id={"email"}
      type={"email"}
      onChange={onFieldsChange}
      value={null}
      error={showError}
    />
  );

  const password = (
    <InputField
      placeholder={"Enter your password"}
      id={"password"}
      type={"password"}
      error={showError}
      onChange={onFieldsChange}
    />
  );

  const forgotPassword = (
    <div className="d-flex justify-content-end">
      <SimpleButton
        text={"Forgot password"}
        action={() => {
          navigateToForgotPassword();
        }}
      />
    </div>
  );

  const submit = (
    <ActionButton
      title={"Log in"}
      action={onLogIn}
      className="mt-60"
      active={loginEnabled}
    />
  );

  const disclaimer = (
    <div className="authentication-disclaimer">
      Don't have an account yet?{" "}
      <SimpleButton
        text={"Register"}
        action={() => {
          navigateToRegister();
        }}
      />
    </div>
  );

  const components = [
    title,
    subtitle,
    separator,
    emailAddress,
    password,
    errorContent,
    forgotPassword,
    submit,
    disclaimer,
  ];

  return (
    <div id="login" className="full-screen-item">
      <ContainerWithLogo components={components} />
      <TrackingComponent />
    </div>
  );
};

export default LoginPage;
