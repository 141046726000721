import $ from "jquery";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputField } from "../../components/generic/InputField";
import { SimpleButton } from "../../components/generic/SimpleButton";
import { ActionButton } from "../../components/generic/ActionButton";
import { ErrorLabel } from "../../components/generic/ErrorLabel";
import { ContainerWithLogo } from "../../components/generic/ContainerWithLogo";
import {
  isNameValid,
  isEmailValid,
  isPasswordValid,
} from "../../helpers/ValidatorHelper";

import { useUserContext } from "../../components/model/context/UserContext";
import LocalStorage from "../../components/model/state/LocalStorageHelper";
import TrackingComponent from "../../components/tracking/TrackingComponent";

import {
  navigateToLogin,
  navigateToDashboard,
} from "../../helpers/NavigationHelper";
import Notifications from "../../helpers/Notifications";

const CreateAccountFromInvitePage = ({ setLoading }) => {
  const [nameValid, setNameValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [registerEnabled, setRegisterEnabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { acceptInvite, navigateToDashboardIfPossible } = useUserContext();

  if (!searchParams.get("token")) {
    // Link is invalid
    navigateToLogin();
    return;
  }

  const onFieldsChange = () => {
    const name = $("#name").val();
    const password = $("#password").val();

    if (name.length > 0 && password.length > 0) {
      setRegisterEnabled(true);
    } else {
      setRegisterEnabled(false);
    }
  };

  const registerAction = () => {
    const name = $("#name").val();
    const password = $("#password").val();
    const token = searchParams.get("token");

    if (!isNameValid(name)) {
      setNameValid(false);
      return;
    }

    if (!isPasswordValid(password)) {
      setPasswordValid(false);
      return;
    }

    setNameValid(true);
    setPasswordValid(true);

    setLoading(true);
    acceptInvite(
      token,
      name,
      password,
      (response) => {
        // User created
        setLoading(false);
        navigateToDashboardIfPossible();
      },
      () => {
        setLoading(false);
        Notifications.showGenericError();
      }
    );
  };

  var errorContent;
  if (nameValid === false || passwordValid === false || showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"The name, or password are not valid."} />
      </div>
    );
  }

  const title = <div className="title text-left mt-24">Accept invite</div>;
  const subtitle = (
    <div className="subtitle text-left mt-8">Create your account now</div>
  );

  const separator = <div className="mt-60"></div>;

  const name = (
    <InputField
      placeholder={"Enter your full name"}
      id={"name"}
      type={"text"}
      error={!nameValid}
      onChange={onFieldsChange}
      value={null}
    />
  );

  const password = (
    <InputField
      placeholder={"Enter your password"}
      id={"password"}
      type={"password"}
      error={!passwordValid}
      onChange={onFieldsChange}
    />
  );

  const submit = (
    <ActionButton
      title={"Accept invite"}
      action={registerAction}
      className="mt-60"
      active={registerEnabled}
    />
  );

  const components = [
    title,
    subtitle,
    separator,
    name,
    password,
    errorContent,
    submit,
  ];

  return (
    <div id="register" className="full-screen-item">
      <ContainerWithLogo components={components} />
      <TrackingComponent />
    </div>
  );
};

export default CreateAccountFromInvitePage;
