import React, { useState } from "react";
import { ReactComponent as SemaphrLogo } from "../../../resources/img/logo.svg";
import { ReactComponent as SemaphrLogoSmall } from "../../../resources/img/logo-small.svg";
import { ReactComponent as MenuButtonSvg } from "../../../resources/img/header-menu-button.svg";
import { ReactComponent as RightArrow } from "../../../resources/img/navigation-right-arrow.svg";
import SiteHeaderButtons from "./SiteHeaderButtons";
import { isMobile } from "react-device-detect";
import {
  navigateToBlog,
  navigateToHomeWithPath,
  navigateToMainPage,
  navigateToRegister,
} from "../../../helpers/NavigationHelper";
const SiteHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <div className="display-on-big">
        <div className="site-header">
          <a
            onClick={() => {
              navigateToMainPage();
            }}
          >
            <SemaphrLogo />
          </a>
          <SiteHeaderButtons />
        </div>
      </div>
      <div className="display-on-small">
        <div className="site-header-small">
          <a
            onClick={() => {
              navigateToMainPage();
            }}
          >
            <SemaphrLogoSmall />
          </a>

          <button className="site-header-button">
            <MenuButtonSvg onClick={() => setMenuOpen(!menuOpen)} />
          </button>
        </div>
        {menuOpen && (
          <nav className="site-header-mobile-nav">
            <ul>
              <li>
                <a
                  href="https://github.com/semaphr"
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="site-header-mobile-normal-option"
                  target="_blank"
                >
                  Documentation
                </a>
                <RightArrow />
              </li>
              <li>
                <a
                  onClick={() => navigateToBlog()}
                  className="site-header-mobile-normal-option"
                >
                  Blog
                </a>
                <RightArrow />
              </li>
              <li>
                <a
                  onClick={() => {
                    navigateToHomeWithPath("#pricing");
                    setMenuOpen(false);
                  }}
                  className="site-header-mobile-normal-option"
                >
                  Pricing
                </a>
                <RightArrow />
              </li>
              <li>
                <a
                  onClick={() => {
                    navigateToHomeWithPath("#faq");
                    setMenuOpen(false);
                  }}
                  className="site-header-mobile-normal-option"
                >
                  FAQs
                </a>
                <RightArrow />
              </li>

              <li>
                <a
                  href="/login"
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="site-header-mobile-normal-option site-header-mobile-colored-option"
                >
                  Log in
                </a>
                <RightArrow style={{ color: "#808eff" }} />
              </li>
            </ul>
            <button
              onClick={() => navigateToRegister()}
              className="site-header-mobile-button"
            >
              Get started →
            </button>
          </nav>
        )}
      </div>
    </>
  );
};

export default SiteHeader;
