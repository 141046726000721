import { POST, GET, DELETE, APIVersion, PUT } from "./APIHelper";

export const affectedUsersAPICall = (
  platform,
  projectID,
  range,
  onSuccess,
  onError
) => {
  const data = {
    platform: platform,
    project_id: projectID,
    range: range,
  };

  const path = APIVersion + "dashboard/affected_users";

  POST(path, data, onSuccess, onError);
};

export const usersOnAppAPICall = (
  platform,
  projectID,
  range,
  onSuccess,
  onError
) => {
  const data = {
    platform: platform,
    project_id: projectID,
    range: range,
  };

  const path = APIVersion + "dashboard/users_on_app_versions";

  POST(path, data, onSuccess, onError);
};
