import $ from "jquery";

import { InputField } from "../../components/generic/InputField";
import { SimpleButton } from "../../components/generic/SimpleButton";
import { ActionButton } from "../../components/generic/ActionButton";
import { ErrorLabel } from "../../components/generic/ErrorLabel";
import { ContainerWithLogo } from "../../components/generic/ContainerWithLogo";
import { navigateToRegister } from "../../helpers/NavigationHelper";
import { useUserContext } from "../../components/model/context/UserContext";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { navigateToLogin } from "../../helpers/NavigationHelper";
import { isPasswordValid } from "../../helpers/ValidatorHelper";
import TrackingComponent from "../../components/tracking/TrackingComponent";

const NewPasswordPage = ({ setLoading }) => {
  const { changePassword } = useUserContext();
  const [resetPasswordEnabled, setResetPasswordEnabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const changePasswordAction = () => {
    const invitationToken = searchParams.get("token");
    const password = $("#password").val();

    console.log(invitationToken);
    console.log(password);

    setLoading(true);
    changePassword(
      invitationToken,
      password,
      (response) => {
        setLoading(false);
        navigateToLogin();
      },
      () => {
        setLoading(false);
        setShowError(true);
        console.log("mata");
      }
    );
  };

  const enableResetPasswordIfNeeded = () => {
    const password = $("#password").val();
    const repeatPassword = $("#repeat-password").val();

    const passwordValid = isPasswordValid(password);
    const repeatPasswordValid = isPasswordValid(repeatPassword);

    if (passwordValid && repeatPasswordValid && password === repeatPassword) {
      setResetPasswordEnabled(true);
      setShowError(false);
    } else {
      setResetPasswordEnabled(false);
      setShowError(true);
    }
  };

  var errorContent;
  if (showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"Passwords don't match or are too short."} />
      </div>
    );
  }

  const title = <div className="title text-left mt-24">New Password</div>;
  const subtitle = (
    <div className="subtitle text-left mt-8">
      Pick a new password to use for your login credentials.
    </div>
  );

  const separator = <div className="mt-60"></div>;

  const password = (
    <InputField
      placeholder={"New Password"}
      id={"password"}
      type={"password"}
      error={showError}
      onChange={enableResetPasswordIfNeeded}
    />
  );

  const repeatPassword = (
    <InputField
      placeholder={"Repeat Password"}
      id={"repeat-password"}
      type={"password"}
      onChange={enableResetPasswordIfNeeded}
      value={null}
      error={showError}
    />
  );

  const submit = (
    <ActionButton
      title={"Reset password"}
      action={changePasswordAction}
      className="mt-60"
      active={resetPasswordEnabled}
    />
  );

  const disclaimer = (
    <div className="authentication-disclaimer">
      Don't have an account yet?{" "}
      <SimpleButton
        text={"Register"}
        action={() => {
          navigateToRegister();
        }}
      />
    </div>
  );

  const components = [
    title,
    subtitle,
    separator,
    password,
    repeatPassword,
    errorContent,
    submit,
    disclaimer,
  ];

  return (
    <div id="new-password" className="full-screen-item">
      <ContainerWithLogo components={components} />
      <TrackingComponent />
    </div>
  );
};

export default NewPasswordPage;
