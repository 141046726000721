const PlatformType = ({ image, title }) => {
  return (
    <div className="platform-type">
      <img className="image" src={image} alt={"platform"} />
      <div className="title">{title}</div>
    </div>
  );
};

export default PlatformType;
